import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';
import { v4 as uuidv4 } from 'uuid';
import CryptoJS  from 'crypto-js';

interface CustomJwtPayload {
  Id: string;
  nombre: string;
  Correo: string;
  Telefono: string;
  Rut: string;
  perfil: string;
  IdentificadorToken: string;
  exp: number;
}

const useLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<any>("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false); 
  // Verifica si hay un token en el almacenamiento local
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // Si hay un token, lo eliminamos para forzar el inicio de sesión
      localStorage.removeItem("token");
      localStorage.removeItem("nombre");
      localStorage.removeItem('perfil');
      localStorage.removeItem('id');
      localStorage.clear()
    }
  }, []);
  const hashPassword = (password:any) => {
    return CryptoJS.SHA256(password).toString(); // Hash SHA256
  };
  const handleLogin = async () => {
    const uuId = uuidv4();
    setLoading(true);
    try {
      const response:any = await fetch(`${apiUrl}Usuarios/Login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
       body: JSON.stringify({ email: email, clave: hashPassword(password) }),
      // body: JSON.stringify({ correo: email, contrasena: password }),
      });
      //console.log(response.ok)
      if (!response.ok) {
        const error = await response.json();
        const errorData = { message: error.mensaje, uuid: uuId }
        setError(errorData);
      } else {
        //console.log('Response:', response);
        const data = await response.json();
        if(data){
          localStorage.setItem('token', data.token);
          localStorage.setItem('nombre', data.nombre);
          localStorage.setItem('perfil', data.perfilDescripcion);
          localStorage.setItem('id', data.id);
        }
        

        navigate('/dashboard');
       // const decodedToken = jwtDecode<CustomJwtPayload>(data.token);
        
      }

    } catch (error: any) {
      localStorage.removeItem('token');
      localStorage.removeItem('nombre');
      localStorage.removeItem('perfil');
      localStorage.removeItem('id');
      const errorData = { message: error.mensaje, uuid: uuId }
      setError(errorData);
    } finally {
      setLoading(false); // Desactivamos el spinner una vez la petición esté completa
    }
  };

  return {
    email,
    setEmail,
    password,
    setPassword,
    error,
    handleLogin,
    loading
  };
};

export default useLogin;

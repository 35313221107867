import { useState, useEffect } from "react";
import {fetchSpecies} from "../../services/species/fetchSpecies";

const useSpecies = (initialPage = 1, pageSize = 5, filterBy = "", searchText = "") => {
  const [species, setSpecies] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(initialPage);

  useEffect(() => {
    const loadSpecies = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await fetchSpecies(page, pageSize, filterBy, searchText);
        setSpecies(data);        
      } catch (error:any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    loadSpecies();
  }, []);

  return { species, loading, error, setPage };
};

export default useSpecies;

import axios, { AxiosRequestConfig } from 'axios';
import { AxiosInterceptor } from './axios.interceptor';


export const callApi = async (config: AxiosRequestConfig): Promise<any> => {
    AxiosInterceptor();
    const api = process.env.REACT_APP_API_URL ;

    try {
        const response = await axios({
            ...config, 
            baseURL: api,
            timeout: 150000,
        });

       //console.log(response);

        return response.data; 
    } catch (error) {
        if (axios.isCancel(error)) {
            throw new Error('Request canceled');
        }
        throw error; 
    }
};

/**
 * Función para formatear un número según las convenciones del idioma español.
 * @param {number} value - El número que deseas formatear.
 * @returns {string} El número formateado.
 */
export const formatNumber = (value: number): string => {

    if (value === null || value === undefined) { value = 0 }

    const roundedValue = Math.round(value);

    return roundedValue.toLocaleString("es-ES", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        useGrouping: true
    });

};

/**
 * Función para descargar un archivo a partir de una cadena codificada en base64.
 * @param {string} base64Data - La cadena en formato base64 que representa los datos del archivo.
 * @param {string} fileName - El nombre que se usará al descargar el archivo.
 * @param {string} mimeType - El tipo MIME del archivo para asegurar una correcta interpretación y formato del contenido.
 * @returns {void} No retorna valor, pero descarga el archivo generado a partir de los datos en base64.
 */
export const handleDownloadBase64 = (data: { base64: string }, fileName: string, mimeType: string): void => {
    const base64String = data.base64.split(',')[1] || data.base64;  // Asegúrate de tomar la parte útil de la cadena Base64

    const byteCharacters = atob(base64String);  // Decodifica la cadena Base64
    const byteNumbers = new Array(byteCharacters.length);

    // Convertir cada carácter decodificado en un código de byte
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    // Crear un Uint8Array con el array de bytes
    const byteArray = new Uint8Array(byteNumbers);

    // Crear un Blob con el tipo MIME adecuado
    const blob = new Blob([byteArray], { type: mimeType });

    // Crear enlace de descarga y descargar el archivo
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


/**
 * Descarga un archivo PDF a partir de una cadena codificada en base64.
 *
 * Esta función convierte una cadena de texto en base64 en un archivo PDF y lo descarga
 * automáticamente en el navegador utilizando un enlace temporal.
 *
 * @param {string} base64Data - La cadena en formato base64 que representa los datos del archivo PDF.
 * @param {string} fileName - El nombre del archivo que se usará al descargarlo, incluyendo la extensión (ej., "archivo.pdf").
 * @returns {void} No retorna ningún valor, pero genera y descarga un archivo PDF a partir de los datos en base64.
 */
export const handleDownloadPDF = (data: { base64: string }, fileName: string): void => {
    //console.log('paso')
    const base64String = data.base64.split(',')[1] || data.base64;  // Asegúrate de tomar la parte útil de la cadena Base64
    //console.log('Base64 string:', base64String);
    const byteCharacters = atob(base64String);  // Decodifica la cadena Base64
    const byteNumbers = new Array(byteCharacters.length);

    // Convertir cada carácter decodificado en un código de byte
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    // Crear un Uint8Array con el array de bytes
    const byteArray = new Uint8Array(byteNumbers);

    // Crear un Blob con el tipo MIME "application/pdf" adecuado para un archivo PDF
    const blob = new Blob([byteArray], { type: "application/pdf" });

    // Crear un enlace temporal de descarga para el Blob y activar la descarga
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


export const handleDownloadPDF2 = (base64Data: string, fileName: string): void => {
  try {
    //console.log("Cadena Base64 recibida:", base64Data);

    // Eliminar posibles caracteres no imprimibles, espacios y saltos de línea
    const cleanedBase64 = base64Data.replace(/[^A-Za-z0-9+/=]/g, "").trim();
    //console.log("Cadena Base64 limpiada:", cleanedBase64);

    // Decodificar Base64
    let byteCharacters;
    try {
      byteCharacters = atob(cleanedBase64);
    } catch (decodeError) {
      console.error("Error al decodificar la cadena Base64:", decodeError);
      throw new Error("La cadena Base64 no pudo ser decodificada.");
    }

    //console.log("Decodificación exitosa, longitud de los caracteres:", byteCharacters.length);

    // Convertir los caracteres en bytes
    const byteNumbers = Array.from(byteCharacters).map((char) => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);

    //console.log("Array de bytes generado, longitud:", byteArray.length);

    // Crear un Blob
    const blob = new Blob([byteArray], { type: "application/pdf" });
    //console.log("Blob creado exitosamente");

    // Descargar el archivo
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    //console.log("Archivo descargado exitosamente:", fileName);
  } catch (error) {
    console.error("Error al descargar el PDF:", error);
  }
};



  
  // Función para validar que la cadena sea Base64
  const isValidBase64 = (str: string) => {
    const base64Regex = /^[A-Za-z0-9+/=]+$/;
    return base64Regex.test(str);
  };
  
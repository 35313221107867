interface IWidget{
    classNameContainer?: string,
    children: any,
    classNameContent?: string
}

const Widget = ({classNameContainer = 'rounded-md bg-white w-fit self-center my-6', children, classNameContent = 'p-5'}: IWidget) => {
  return (
    <div className={classNameContainer}>
        <div className={classNameContent}>
            {children}
        </div>
    </div>
  );
};
  
export default Widget;
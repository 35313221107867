import { Navigate } from 'react-router-dom';
import React from 'react';

interface PrivateRouteProps {
  children: React.ReactNode;
}

function PrivateRoute({ children }: PrivateRouteProps) {
  const isAuthenticated = localStorage.getItem('token');

  // Si el usuario no está autenticado, redirigir a login
  if (!isAuthenticated) {
    localStorage.clear();  // Limpia localStorage si no hay token
    return <Navigate to="/login" replace />;
  }

  // Si el usuario está autenticado, permitir acceso a la ruta privada
  return <>{children}</>;
}

export default PrivateRoute;

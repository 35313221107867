import { useState, useCallback } from 'react';
import {handleDownloadPDF} from "../../services/utilservices";
import {fetchProcessReport} from "../../services/producers/fetchProcessReport";

export const useProcessReport = () => {
  const [dataProcessReport, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getProcessReport = useCallback(async (lote:string, especie:string, variedad:string, codigo: string) => {
    try {
      setLoading(true);
      const response: any = await fetchProcessReport(lote, especie);
      const now = new Date();
      const date = now.toLocaleDateString();
      const hora = now.toLocaleTimeString();
      const fileNameParts = [
        codigo, // Se incluye si no es null ni undefined
        especie, // Se incluye si no es null ni undefined
        variedad, // Se incluye si no es null ni undefined
        lote, // Se incluye si no es null ni undefined
        date, // Siempre incluido
      ];
      
      // Filtra los valores nulos o indefinidos y los une con guiones bajos
      const fileName = fileNameParts
        .filter((part) => part != null && part !== "") // Filtrar null, undefined y cadenas vacías
        .join("_") + ".pdf";
      if (response) {
        handleDownloadPDF(response, fileName,);
      } else {
        console.error("Error en la operación:", response?.data.mensaje);
      }
      setData(response);
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, []);

  return { dataProcessReport, loading, error, getProcessReport };
};

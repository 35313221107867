import React, {useEffect, useState} from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import {PaginationInterface} from "./interfaces/paginationInterface";

const Paginate = ({  pageNumber = 1, pageSize = 10, totalCount = 1, onPageChange, onPageSizeChange }: PaginationInterface) => {

  if(!pageNumber) pageNumber = 1;
  if(!pageSize) pageSize = 10;
  if(!totalCount) totalCount = 1

  const [currentPage, setCurrentPage] = useState(pageNumber);
  const [selectedPageSize, setSelectedPageSize] = useState(pageSize);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    setCurrentPage(pageNumber ?? 1);
  }, [pageNumber]);

  useEffect(() => {
    if ([5, 10, 20, 50].includes(pageSize ?? 10)) {
      setSelectedPageSize(pageSize ?? 10);
    } else {
      setSelectedPageSize(10); // Valor por defecto
    }
  }, [pageSize]);

  useEffect(() => {
    if (selectedPageSize > 0 && (totalCount ?? 0) >= 0) {
      setTotalPages(Math.ceil((totalCount ?? 0) / selectedPageSize));
    }
  }, [totalCount, selectedPageSize]);

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    } else if (currentPage < 1) {
      setCurrentPage(1);
    }
  }, [totalPages, currentPage]);

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
    onPageChange?.(page);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      setCurrentPage(newPage);
      onPageChange?.(newPage);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setCurrentPage(newPage);
      onPageChange?.(newPage);
    }
  };

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSize = Number(e.target.value);
    setSelectedPageSize(newSize);
    setCurrentPage(1);
    onPageSizeChange?.(newSize);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 6;
    const leftSiblingIndex = Math.max(currentPage - 1, 1);
    const rightSiblingIndex = Math.min(currentPage + 1, totalPages);

    // Always show the first page
    pageNumbers.push(1);

    // Add left ellipsis if needed
    if (leftSiblingIndex > 2) {
      pageNumbers.push('...');
    }

    // Add the middle page numbers
    for (let i = leftSiblingIndex; i <= rightSiblingIndex; i++) {
      if (i > 1 && i < totalPages) {
        pageNumbers.push(i);
      }
    }

    // Add right ellipsis if needed
    if (rightSiblingIndex < totalPages - 1) {
      pageNumbers.push('...');
    }

    // Always show the last page if totalPages > 1
    if (totalPages > 1) {
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">

      <div className="sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <nav aria-label="Pagination" className="isolate inline-flex rounded-md shadow-sm">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              className="relative flex rounded-[3px] text-[16px] font-[400] items-center justify-center w-[25px] h-[25px] text-sm text-gray-900 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <ChevronLeftIcon aria-hidden="true" className="h-5 w-5" />
            </button>

            {getPageNumbers().map((page, index) => (
              <button
                key={index}
                onClick={() => typeof page === 'number' && handlePageClick(page)}
                className={`relative mx-1 flex rounded-[3px] text-[16px] font-[400] items-center justify-center w-[25px] h-[25px] text-sm ${
                  currentPage === page
                    ? 'bg-[#003DA5] text-white'
                    : 'text-gray-900 hover:bg-gray-50'
                }`}
                disabled={typeof page !== 'number'}
              >
                {page}
              </button>
            ))}

            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="relative flex rounded-[3px] text-[16px] font-[400] items-center justify-center w-[25px] h-[25px] text-sm text-gray-900 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <ChevronRightIcon aria-hidden="true" className="h-5 w-5" />
            </button>
          </nav>
        </div>
        <div>
          <p className="text-sm text-gray-700">
            Filas por página
            <select
              value={selectedPageSize}
              onChange={handlePageSizeChange}
              selected-value={selectedPageSize}
              className={`mx-2`}
            >
              {[5, 10, 20, 50].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
            <span className="font-medium">
              {(currentPage - 1) * selectedPageSize + 1}
            </span>{' '}
            to{' '}
            <span className="font-medium">
              {Math.min(currentPage * selectedPageSize, totalCount)}
            </span>{' '}
            of <span className="font-medium">{totalCount}</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Paginate;
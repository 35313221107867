// axios.interceptor.ts
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';

export const AxiosInterceptor = () => {
  // Interceptor para solicitudes
  axios.interceptors.request.use(
    (config) => {
      const apiToken = localStorage.getItem("token");
      //console.log(apiToken)
      if (apiToken) {
        config.headers['Authorization'] = `Bearer ${apiToken}`;
      }
      config.headers['Content-Type'] = 'application/json';
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Interceptor para respuestas
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        localStorage.clear();
        // Aquí lanzamos un error personalizado que puedes manejar en tu componente
        return Promise.reject({ ...error, customMessage: "SessionExpired" });
      }

      return Promise.reject(error);
    }
  );
};

import {callApi} from "../utilservices";

export const fetchVarieties = async (pageNumber = 1, pageSize = 3, filtrarPor = "", textoBusqueda = "") => {
  const url = "/Miscelaneo/Variedad";

  try {

    return await callApi({
      url: url,
      method: 'GET',
      responseType: 'json',
    });
    // const response = await fetch(url, {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "Authorization": `Bearer ${token}`,
    //   },
    // });
    //
    // if (!response.ok) {
    //   throw new Error("Error in API request");
    // }
    //
    // const data = await response.json();
    // return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

import Navtab from "../components/navtab";
import BarChart from "./widgets/barChart";
import CurvaProduccion from "./widgets/curvaProduccion";
import Filtros from "./widgets/filtros";
import RowInfo from "./widgets/rowInfo";
import SegregacionProduccion from "./widgets/segregacionProduccion";
import useDashboard from "../../hooks/dashboard/useDashboard";
import {useEffect, useMemo, useState} from "react";

const Dashboard = ({ productorPadreId, productorId, temporadaId, especieId, variedadId }:any) => {

  const [selectedParentProducerId, setSelectedParentProducerId] = useState(productorPadreId);
  const [selectedProducerId, setSelectedProducerId] = useState(productorId);
  const [selectedSeasonId, setSelectedSeasonId] = useState(temporadaId);
  const [selectedSpeciesId, setSelectedSpeciesId] = useState(especieId);
  const [selectedVarietyId, setSelectedVarietyId] = useState(variedadId);
  const [isFiltersSynced, setIsFiltersSynced] = useState(false);

  useEffect(() => {
    // Simular la sincronización con el almacenamiento local o actualización de los filtros
    const syncFilters = () => {
      // Aquí puedes agregar lógica si necesitas verificar los valores con el localStorage
      setIsFiltersSynced(true);
    };

    // Ejecutar al montar el componente para simular que los filtros precargados están listos
    syncFilters();
  }, []);

  // Monitorear los cambios en los filtros para activar la sincronización
  useEffect(() => {
    setIsFiltersSynced(true); // Marca como sincronizado cada vez que se actualizan los filtros
  }, [selectedParentProducerId, selectedProducerId, selectedSeasonId, selectedSpeciesId, selectedVarietyId]);

  const filters = useMemo(() => ({
    productorPadreId: selectedParentProducerId,
    productorId: selectedProducerId,
    temporadaId: selectedSeasonId,
    especieId: selectedSpeciesId,
    variedadId: selectedVarietyId,
  }), [selectedParentProducerId, selectedProducerId, selectedSeasonId, selectedSpeciesId, selectedVarietyId]);

  const { dashboardData } = useDashboard(isFiltersSynced ? filters : null);

  let estimates:any = [];
  let segregations:any = [];
  let caliber:any = [];
  let productorNombre:any = "";

  const weeks:any[] = [];
  const segregacionData:any[] = [];
  const CurvaProduccionData:any[] = [];

  if(dashboardData.data){
    estimates = dashboardData.data.estimaciones;
    segregations = dashboardData.data.segregaciones;
    caliber = dashboardData.data.calibres;

    estimates.forEach((element:any)=> {
      productorNombre = estimates[0].productorNombre;

      weeks.push({
        name: element.semana,
        recepcionReal: element.totalKiloProceso,
        recepcionEstimada: element.totalKiloNeto,
      });
      weeks.push(element.semana)
    })

    segregations.forEach((element:any)=>{
      productorNombre = segregations[0].productorNombre;

      segregacionData.push({
        name:element.tipificacion,
        percent1:element.totalKilosAsociados,
        productorNombre: element.productorNombre
        // percent2: 66
      })
    })

    caliber.forEach((element:any)=>{
      productorNombre = caliber[0].productorNombre;

      CurvaProduccionData.push({
        name:element.tamano,
        percent1:element.totalKilosAsociados,
      })
    })
  }

  return(
    <div className="flex flex-col w-full h-full bg-white" style={{fontFamily: 'Cairo'}}>
      <div className={`flex flex-wrap bg-[linear-gradient(90deg,_#01AAEF_0.72%,_#2C2D65_100%)] py-[23px] px-[35px] min-h-[181px]`}>
        <Navtab />
        <Filtros
          column={`nombrePadre`}
          onProducerChange={setSelectedProducerId}
          onParentProducerChange={setSelectedParentProducerId}
          onSeasonChange={setSelectedSeasonId}
          onSpeciesChange={setSelectedSpeciesId}
          onVarietyChange={setSelectedVarietyId}
        />
      </div>
      <div className={`px-6`}>
        <RowInfo rowData={dashboardData.balance} />
        <BarChart data={dashboardData.graficoRecepcion} productorNombre={productorNombre}/>
        <div className="curve-segregation flex flex-row flex-wrap gap-5">
            <SegregacionProduccion data={dashboardData.graficoSegregacion} productorNombre={productorNombre} />
            <CurvaProduccion data={dashboardData.graficoCalibre} productorNombre={productorNombre}/>
        </div>
        {/* <CurvaP roduccion /> */}
        {/* <Dropdown description="aaa" childrenOptions={[{name:'dafdfd', quantity:3454}]}/> */}
      </div>
    </div>
  )
}

export default Dashboard;
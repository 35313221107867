import {callApi, handleDownloadBase64} from "../utilservices";
import {ExportExcelReceptionsInterface} from "./interfaces/exportExcelReceptionsInterface";

export const fetchExportReceptions= async (filters:ExportExcelReceptionsInterface[]) => {
  let url= `Recepciones/Consolidado`;
  try {
    const response = await callApi({
      url: url,  
      method: 'POST',
      data: {
        paginationFilters: filters,
      },
      responseType: 'json',
    });

    return response;

  } catch (error) {
    console.error('Error al descargar el archivo:', error);
  } finally {
    // setLoading(false);
  }
};
import React, {useEffect, useState} from 'react';
import {ReactComponent as WarningLogin} from "../../../assets/icons/warning.svg";
import {ReactComponent as Close} from "../../../assets/icons/close.svg";


interface AlertaProps {
  type: 'error' | 'success' | 'info' | 'warning';
  message: string;
  subtitle: string;
  onClose?: (close:boolean) => void;
  className?: string;
  toggleAlert: boolean; // Nuevo prop
  setToggleAlert: React.Dispatch<React.SetStateAction<boolean>>; // Nuevo prop
}

const DDCAlert: React.FC<AlertaProps> = ({ type, message, subtitle=null, onClose, className, toggleAlert, setToggleAlert }) => {
  // const [toggleAlert, setToggleAlert] = useState<boolean>(true);

  const style_type = {
    error: 'bg-white text-red-700',
    success: 'bg-white text-green-700',
    info: 'bg-white text-blue-700',
    warning: 'bg-white text-yellow-700',
  };

  useEffect(() => {
    console.log('toggleAlert value changed:', toggleAlert);
  }, [toggleAlert]);

  const handleClose = () => {
    setToggleAlert(false);
    onClose?.(toggleAlert)
  }

  return (
    <div toggle-alert={`${toggleAlert}`}>
      { toggleAlert && (
      <div className={`flex items-center justify-between border-2 p-4 ${style_type[type]} rounded-md mb-4 dev-ddc-alert mt-10 mr-7 min-w-[422px] px-[30px] py-[18px] ${className}`}>
        <div className="flex items-center">
          {/* Icono de alerta */}
          <span className="mr-2 w-[40px] h-[40px] bg-[#E36464] rounded-full flex justify-center items-center flex-shrink-0">
            <WarningLogin/>
          </span>
          <div className='w-full flex flex-col'>
            <span className="w-full font-semibold text-base mx-3 dev-font-f-titi-web">{message}</span>
            <span className="w-full text-font-blue-gray text-sm mx-3 dev-font-f-roboto">{subtitle}</span>
          </div>
        </div>
        <button onClick={()=>{}} className="text-gray-500 hover:text-gray-700">
          <Close onClick={handleClose}/>
        </button>
      </div>
      )}
    </div>
  );
};

export default DDCAlert;

import React, { useEffect, useState } from "react";
import useData from "../user-data/userData";
import usePassword from "./userPassword";
import { useSnackbar } from '../../../context/SnackbarContext';
import CryptoJS from 'crypto-js';

const UserPassword = () => {
  const { data, handleData } = useData();
  const { passwordResponse, handlePassword } = usePassword();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [formValues, setFormValues] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    correo: "",
    nombre: "",
    productores: "",
    rut: "",
    telefono: "",
    perfil: 0
  });
  
  const { showMessage } = useSnackbar();

  const validatePassword = (password: string) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*.]/.test(password);
    const isLongEnough = password.length >= 8;
  
    if (!hasUpperCase) {
      return 'La contraseña debe contener al menos una letra mayúscula.';
    }
    if (!hasSpecialChar) {
      return 'La contraseña debe contener al menos un carácter especial.';
    }
    if (!isLongEnough) {
      return 'La contraseña debe tener al menos 12 caracteres.';
    }
  };

  const hashPassword = (password: any) => {
    return CryptoJS.SHA256(password).toString(); // Hash SHA256
  };

  // Ejecuta `handleData` una sola vez al cargar el componente
  useEffect(() => {
    handleData();
  }, []);

  // Actualiza `formValues` cuando `data` cambie y esté disponible
  useEffect(() => {
    if (data) {
      setFormValues({
        currentPassword: data.contrasena || "",
        newPassword: "",
        confirmPassword: "",
        correo: data.correo,
        nombre: data.nombre,
        productores: data.productores,
        rut: data.rut,
        telefono: data.telefono,
        perfil: data.perfil
      });
    }
  }, [data]);

  // Verifica si el usuario es administrador desde localStorage
  const perfil = localStorage.getItem('perfil');
  const isAdmin = perfil === 'Administrador' || perfil === 'Productor' || perfil === 'Estandar';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!formValues.currentPassword || !formValues.newPassword || !formValues.confirmPassword) {
      showMessage('Debe completar todos los campos', 'error');
      return;
    }
    const passwordValidationResult = validatePassword(formValues.newPassword);
    if (typeof passwordValidationResult === 'string') {
      showMessage(passwordValidationResult, 'error');
      return;
    }
    if (formValues.newPassword !== formValues.confirmPassword) {
      showMessage('Las contraseñas no coinciden', 'error');
      return;
    }
    setPasswordError('');
    handlePassword(
      data?.id,
      formValues.currentPassword ? hashPassword(formValues.currentPassword) : "",
      formValues.newPassword ? hashPassword(formValues.newPassword) : "",
      formValues.confirmPassword ? hashPassword(formValues.confirmPassword) : "",
      formValues.correo,
      formValues.nombre,
      formValues.productores,
      formValues.rut,
      formValues.telefono,
      formValues.perfil
    );
  };

  return (
    <div>
      <div className="">
        <h3 className="text-[24px] font-normal mb-[13px]">Mi contraseña</h3>
        <hr />
        {isAdmin ? (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
              <div>
                <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">Contraseña Actual</label>
                <input
                  type="password"
                  name="currentPassword"
                  placeholder="Ingresa tu contraseña actual"
                  value={formValues.currentPassword}
                  onChange={handleChange}
                  className="outline-0 w-full h-[42px] px-3 py-[9px] border border-gray-300 rounded-[6px] bg-gray-100"
                />
              </div>
              <div>
                <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">Nueva contraseña</label>
                <input
                  type="password"
                  name="newPassword"
                  placeholder="Ingresa tu nueva contraseña"
                  value={formValues.newPassword}
                  onChange={handleChange}
                   autoComplete="off"
                  className="outline-0 w-full h-[42px] px-3 py-[9px] border border-gray-300 rounded-[6px]"
                />
              </div>
              <div>
                <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">Confirma nueva contraseña</label>
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirma tu nueva contraseña"
                  value={formValues.confirmPassword}
                  onChange={handleChange}
                  autoComplete="off"
                  className="outline-0 w-full h-[42px] px-3 py-[9px] border border-gray-300 rounded-[6px]"
                />
                {passwordError && <p className="text-red-500 text-sm">{passwordError}</p>}
              </div>
            </div>
            <div className="mt-6 text-right">
              <button
                type="submit"
                className="w-[196px] h-[42px] bg-[#00C7B1] rounded-[6px] text-[16px] font-semibold text-white"
              >
                Guardar
              </button>
            </div>
          </form>
        ) : (
          <p>No tienes permisos para cambiar la contraseña.</p>
        )}
      </div>
    </div>
  );
};

export default UserPassword;

import {callApi} from "../utilservices";

/*export const fetchProcessReport = async (lote:string, especie:string) => {
  const url = `Proceso/Informe/${lote}/${especie}`;
  try {
    return await callApi({
      url: url,
      method: 'GET',
      responseType: 'json',
    });
  } catch (error) {
    console.error('Failed to fetch informe calidad:', error);
    throw error;
  }
};*/

import axios from 'axios';

export const fetchProcessReport = async (lote: string, especie: string): Promise<any> => {
  const baseUrlenv = process.env.REACT_APP_API_URL;
  const url = `${baseUrlenv}Proceso/Informe/${lote}/${especie}`;
  try {
    return await callApi({
      url: url,
      method: 'GET',
      responseType: 'json',
    });
  } catch (error) {
    console.error('Failed to fetch informe calidad:', error);
    throw error;
  }
};

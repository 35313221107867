// SnackbarContext.tsx
import React, { createContext, useContext, useState } from 'react';
import Snackbar from '../modules/components/Snackbar'; // Asegúrate de que la ruta sea correcta


interface SnackbarContextProps {
  showMessage: (message: string, type?: 'success' | 'error' | 'info') => void;
}

const SnackbarContext = createContext<SnackbarContextProps | undefined>(undefined);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar debe usarse dentro de un SnackbarProvider');
  }
  return context;
};

export const SnackbarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarType, setSnackbarType] = useState<'success' | 'error' | 'info'>('info');

  const showMessage = (message: string, type: 'success' | 'error' | 'info' = 'info') => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setSnackbarOpen(true);

    setTimeout(() => {
      setSnackbarOpen(false);
    }, 3000);
  };

  return (
    <SnackbarContext.Provider value={{ showMessage }}>
      {children}
      {snackbarMessage && (
        <Snackbar
          message={snackbarMessage}
          type={snackbarType}
          isOpen={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
        />
      )}
    </SnackbarContext.Provider>
  );
};

import React from "react";
import {ReactComponent as Close} from "../../../assets/icons/close.svg";

interface ModalProps {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  content: React.ReactNode | string; // Permitir contenido HTML/JSX
  contentClassName?: string; // Clase CSS personalizada para el contenido
}

const Modal: React.FC<ModalProps> = ({ isVisible, onClose, title, content, contentClassName }) => {
  if (!isVisible) return null; // Si no está visible, no se renderiza nada

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white w-full max-w-md p-6 rounded-lg shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">{title}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-800">
            <Close/>
          </button>
        </div>
        <div className={`mt-4 ${contentClassName ? contentClassName : ""}`}>
          {content}
        </div>
        <div className="mt-6 text-right">
          <button
            onClick={onClose}
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;

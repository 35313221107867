import { callApi } from "../utilservices";

interface Producer {
  id: number;
  codigoSAP: string;
  nombre: string;
  rut: string;
  correo: string | null;
  productorPadreId: number;
  codigoSAPPadre: string;
  nombrePadre: string;
  rutPadre: string;
  correoPadre: string | null;
  esPadre: boolean ;
}
//console.log('paso')
export const fetchProducers = async (
  pageNumber = 1,
  pageSize = 10,
  filtrarPor = "",
  textoBusqueda = ""
) => {
  const baseUrlenv = process.env.REACT_APP_API_URL;
  const season = localStorage.getItem('season'); 
  let idseason=0
  if(season=="2024-2025"){
   idseason=5;
  }
  else{
    idseason=1;
  }
  let url = `${baseUrlenv}Productor/${pageNumber}/${pageSize}`;
  
  console.log(filtrarPor,textoBusqueda)
  if (filtrarPor !== "" && textoBusqueda !== "") {
    url += `/${filtrarPor}/${textoBusqueda}`;
  } else {
    url += `/null/null`;
  }

  const perfilid = localStorage.getItem("id");
  const perfil = localStorage.getItem("perfil");
  url +=`/${idseason}`;
  try {
    let productoresIds: number[] = [];

    // Si el perfil es "Productor", obtenemos los IDs de productores asociados al usuario
    if (perfil === "Productor") {
      const userResult = await callApi({
        url: `Usuarios/${perfilid}`, // Cambia esto a la ruta correcta para obtener el usuario
        method: "GET",
      });

      productoresIds = userResult.productores || [];
      pageSize = 2000;
      localStorage.setItem('prod', JSON.stringify(productoresIds));
      url = `${baseUrlenv}Productor/${pageNumber}/2000/`
      if (filtrarPor !== "" && textoBusqueda !== "") {
        url += `/${filtrarPor}/${textoBusqueda}`;
      } else {
        url += `/null/null`;
      }
      url +=`/${idseason}`;
    }
    else{
      localStorage.removeItem("prod");
    }

    // Hacemos la llamada a la API de productores
    const response = await callApi({
      url: url,
      method: "GET",
      responseType: "json",
    });

    // Extrae la lista de productores
    const allProducersList = response.lista || [];
   // console.log(allProducersList)
    // Aplica el filtro si el perfil es "Productor"
    const filteredProducersList =
      perfil === "Productor"
        ? allProducersList.filter((producer: Producer) =>
            productoresIds.includes(producer.productorPadreId) &&
            producer.esPadre
          )
        : allProducersList;
  
    // Mantén la estructura JSON original
    return {
      ...response,
      lista: filteredProducersList,
    };
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

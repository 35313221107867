// src/hooks/useVarieties.js
import { useState, useEffect } from "react";
import {fetchVarieties} from "../../services/varieties/fetchVarieties";

const useVarieties = (initialPage = 1, pageSize = 5, filterBy = "", searchText = "") => {
  const [varieties, setVarieties] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(initialPage);

  useEffect(() => {
    const loadVarieties = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await fetchVarieties(page, pageSize, filterBy, searchText);
        setVarieties(data);
      } catch (error:any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    loadVarieties();
  }, []);

  return { varieties, loading, error, setPage };
};

export default useVarieties;

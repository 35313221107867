import { useState, useEffect } from "react";
import {fetchProducers} from "../../services/producers/fetchProducers";

const useProducers = (initialPage = 1, pageSize = 5, filterBy = "", searchText = "",selectedParentProducerId="") => {
  const [producers, setProducers] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(initialPage);

  useEffect(() => {
    if (!filterBy) return; 
    const loadProducers = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await fetchProducers(page, pageSize, filterBy, searchText);
        setProducers(data);
      } catch (error:any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    loadProducers();
  }, [page, pageSize, filterBy, searchText]);

  return { producers, loading, error, setPage };
};

export default useProducers;

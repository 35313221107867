import { useState, useEffect } from "react";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
// Valor por defecto
const Navtab = () => {
  const [nombreUsuario, setNombreUsuario] = useState<string>("Usuario");

  useEffect(() => {
    const nombre = localStorage.getItem("nombre") || "Usuario";
    setNombreUsuario(nombre); // Actualiza el estado con el nombre del usuario.
  }, []); 
  return (
    <div>
        <div className="">
            <h3 className="text-2xl pb-2 page-title text-[#FFFFFF]">Dashboard: Bienvenido, {nombreUsuario}.</h3>
            <p className="text-sm flex gap-2 text-[#FFFFFF] page-subtitle pb-4">Indicadores productivos (Recepciones (Kg), Calibres y Segregaciones).<InformationCircleIcon className="size-5 text-[#FFFFFF]"/></p>
        </div>
    </div>
  );
};
  
export default Navtab;
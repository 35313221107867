import React, { useState, useRef, useEffect } from "react";
import { ChevronDownIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down.svg";

interface Option {
  name: string;
  quantity: number;
}

interface ICustomSelect {
  description: string;
  childrenOptions: Option[];
  relationOptions: string[];
  onInputChange: any;
  searchValue: string;
  fieldName: string;
  onSelectChange?: any;
  name?: any;
  reset?: boolean;
  selectedValue?: any;
}

const Dropdown = ({
  description, childrenOptions, relationOptions, onInputChange, onSelectChange, name, reset, selectedValue, searchValue, fieldName
}: ICustomSelect) => {

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(searchValue);
  const [selectedOption, setSelectedOption] = useState("Todas");
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const dropdownRef = useRef<HTMLDivElement>(null); // Crear una referencia para el dropdown

  const handleInputChange = (e: any) => {
    onInputChange(e.target.value);
    setSearchTerm(e.target.value)
  };

  const handleSelectChange = (parentId: any) => {
    if (onSelectChange) onSelectChange(parentId)
    setSelectedOptionId(parentId)
  };

  let filteredOptions = childrenOptions;

  if (searchTerm !== "") {
    filteredOptions = childrenOptions.filter((option) => {
      if (option.name) {
        return option.name.toLowerCase().includes(searchTerm.toLowerCase())
      }
      return true;
    });
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false); // Cierra el dropdown si se hace clic fuera de él
    }
  };

  useEffect(() => {

    if (reset) {
      // Reinicia los valores a sus valores por defecto
      setSearchTerm("");
      setSelectedOption("Todas");
      setSelectedOptionId(null);
    }
  }, [reset]);

  useEffect(() => {
    if (searchValue == '') {
      setSearchTerm("");
    }

  }, [searchValue])

  // Asigna el valor por defecto cuando `selectedValue` está disponible
  useEffect(() => {
    if (selectedValue != null) {
      const defaultOption: any = childrenOptions.find((option: any) => option.id === selectedValue);
      if (defaultOption) {
        setSelectedOption(defaultOption.nombre);

        if (fieldName !== '') localStorage.setItem(fieldName, defaultOption.nombre);
        handleSelectChange(defaultOption.id);
      }
    } else {
      setSelectedOption("Todas");
    }
  }, [selectedValue, childrenOptions]);

  useEffect(() => {    
    document.addEventListener("mousedown", handleClickOutside); // Agrega el evento de clic al documento

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Limpia el evento al desmontar el componente
    };
  }, []);

  return (
    <div className="relative inline-block default-font" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="button-filter-option bg-gray-100 border rounded-custom border-gray-200 shadow-sm px-4 py-2 text-left cursor-pointer flex justify-between items-center">
        {description}:
        <span className="font-bold pr-2 ">{selectedOption}</span>
        <ArrowDown />
      </button>

      {isOpen && (
        <div className="absolute mt-2 w-[350px] bg-white rounded-md shadow-lg z-10">
          <div className="px-4 pt-1">
            <h5>
              {description}: <span className="font-bold">{selectedOption}</span>
            </h5>
          </div>
          <div className="px-4 py-2">
            <div className="flex items-center border-b border-gray-300">
              <MagnifyingGlassIcon
                className="group pointer-events-none size-5 text-font-blue-gray pb-1"
                aria-hidden="true"
              />
              <input
                type="text"
                placeholder="Buscar..."
                value={searchTerm}
                onChange={handleInputChange}
                className="w-full px-2 pb-2 focus:outline-none placeholder:text-xs placeholder:font-bold"
              />
            </div>
            <p className="text-gray-500 text-sm mt-3 font-bold">{description}</p>
          </div>
          <ul className="max-h-40 overflow-auto">
            
            {filteredOptions.map((option: any) => (
              relationOptions.length != 0 && (relationOptions.includes(option.id) || option.id == null) ?
              
                <li
                  key={option.id}
                  className="flex items-center gap-2 px-4 py-2 hover:bg-gray-100 cursor-pointer"
                >                   
                  {/* Radio Button */}
                  <label className="flex items-center">
                    <input
                      type="radio"
                      name="especie"
                      className="form-radio h-4 w-4 text-blue-600"
                      selected-id={selectedOptionId}
                      option-id={option.id}
                      value={option[name]}
                      checked={selectedOptionId === option.id}
                      onChange={() => {
                        setSelectedOption(option[name]);
                        handleSelectChange(option.id);
                        setIsOpen(false);
                      }}
                    />
                    <span className="ml-2 text-sm font-bold">{option != null ? (option[name]) : 'Todas'}</span>
                  </label>
                  {/*<span className="text-gray-500">{option.quantity}</span>*/}
                </li>
                : relationOptions.length == 0 || option.id == null ?
                  <li
                    key={option.id}
                    className="flex items-center gap-2 px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  >
                    {/* Radio Button */}
                    <label className="flex items-center">
                      <input
                        type="radio"
                        name="especie"
                        className="form-radio h-4 w-4 text-blue-600"
                        selected-id={selectedOptionId}
                        option-id={option.id}
                        value={option[name]}
                        checked={selectedOptionId === option.id}
                        onChange={() => {
                          setSelectedOption(option[name]);
                          handleSelectChange(option.id);
                          setIsOpen(false);
                        }}
                      />
                      <span className="ml-2 text-sm font-bold">{option != null ? (option[name]) : 'Todas'}</span>
                    </label>
                    {/*<span className="text-gray-500">{option.quantity}</span>*/}
                  </li>
                  : ''
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;

import Dropdown from "../../../components/dropdown";
import React, { useEffect, useState } from "react";
import useProducers from "../../../../hooks/producers/useProducers";
import useChildProducers from "../../../../hooks/producers/useChildProducers";
import useSeasons from "../../../../hooks/seasons/useSeasons";
import useSpecies from "../../../../hooks/species/useSpecies";
import useVarieties from "../../../../hooks/varieties/useVarieties";

interface IFilterSelect {
  column: string;
  onProducerChange: (id: any) => void;
  onParentProducerChange: (id: any) => void;
  onSeasonChange: (id: any) => void;
  onSpeciesChange: (id: any) => void;
  onVarietyChange: (id: any) => void;
}

const Filtros = ({ column, onProducerChange, onParentProducerChange, onSeasonChange, onSpeciesChange, onVarietyChange }: IFilterSelect) => {

  // Estados para cada filtro, inicializados desde localStorage si existen
  const [selectedProducerId, setSelectedProducerId] = useState<any>(
    JSON.parse(localStorage.getItem("filters") || "{}").producerId || null
  );
  const [selectedParentProducerId, setSelectedParentProducerId] = useState<any>(
    JSON.parse(localStorage.getItem("filters") || "{}").parentProducerId || null
  );
  const [selectedSeasonId, setSelectedSeasonId] = useState<any>(
    JSON.parse(localStorage.getItem("filters") || "{}").seasonId || null
  );
  const [selectedSpeciesId, setSelectedSpeciesId] = useState<any>(
    JSON.parse(localStorage.getItem("filters") || "{}").speciesId || null
  );
  const [selectedVarietyId, setSelectedVarietyId] = useState<any>(
    JSON.parse(localStorage.getItem("filters") || "{}").varietyId || null
  );

  const [parentProducerSearch, setParentProducerSearch] = useState(localStorage.getItem("parentProducerSearch") || "");
  const [producerSearch, setProducerSearch] = useState(localStorage.getItem("producerSearch") || "");
  const [seasonSearch, setSeasonSearch] = useState(localStorage.getItem("seasonSearch") || "");
  const [speciesSearch, setSpeciesSearch] = useState(localStorage.getItem("speciesSearch") || "");
  const [varietySearch, setVarietySearch] = useState(localStorage.getItem("varietySearch") || "");

  interface Especie {
    especieId: string;
    variedades: string[];
  }

  const [reset, setReset] = useState(false);

  const [relations, setRelations] = useState<any>({
    temporadas: [],
    especies: []
  });

  const [inputValue, setInputValue] = useState("");
  const [selectId, setSelectId] = useState(null);
  const { producers } = useProducers(1, 10, column, parentProducerSearch, selectedParentProducerId);
  const { children } = useChildProducers(1, 10, column, producerSearch, selectId);
  const { seasons } = useSeasons(1, 10, column, seasonSearch);
  const { species } = useSpecies(1, 10, column, speciesSearch);
  const { varieties } = useVarieties(1, 10, column, varietySearch);
  interface FilterByInterface {
    pageNumber: number;
    parentProducerId?: number | null;
    seasonId?: number | null;
    speciesId?: number | null;
    varietyId?: number | null;
    producerId?: number | null;
    // Añadir otras propiedades según tu interfaz
  }
  useEffect(() => {
    onProducerChange(selectedProducerId);
    onParentProducerChange(selectedParentProducerId);
    onSeasonChange(selectedSeasonId);
    onSpeciesChange(selectedSpeciesId);
    onVarietyChange(selectedVarietyId);
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "filters",
      JSON.stringify({
        producerId: selectedProducerId,
        parentProducerId: selectedParentProducerId,
        seasonId: selectedSeasonId,
        speciesId: selectedSpeciesId,
        varietyId: selectedVarietyId,
      })
    );
  }, [selectedProducerId, selectedParentProducerId, selectedSeasonId, selectedSpeciesId, selectedVarietyId]);

  const handleInputChange = (dropdownType: string, value: string) => {
    setInputValue(value);
    switch (dropdownType) {
      case "producerSearch":
        setProducerSearch(value);
        localStorage.setItem("producerSearch", value);
        break;
      case "parentProducerSearch":
        setParentProducerSearch(value);
        localStorage.setItem("parentProducerSearch", value);
        break;
      case "seasonSearch":
        setSeasonSearch(value);
        localStorage.setItem("seasonSearch", value);
        break;
      case "speciesSearch":
        setSpeciesSearch(value);
        localStorage.setItem("speciesSearch", value);
        break;
      case "varietySearch":
        setVarietySearch(value);
        localStorage.setItem("varietySearch", value);
        break;
      default:
        break;
    }
  };

  const handleSelectChangeRelation = async () => {
    const savedFilters = JSON.parse(localStorage.getItem('filters') || '{}');

    if (selectedSpeciesId == savedFilters.speciesId && selectedProducerId == savedFilters.producerId && selectedParentProducerId == savedFilters.parentProducerId && selectedSeasonId == savedFilters.seasonId && selectedVarietyId == savedFilters.varietyId) {
      await fetchEspeciesandVariety();
    }

  }

  const handleSelectChange = async (parentId: any, isParent: boolean = false) => {
    setSelectId(parentId);

    if (isParent) {
      if (selectedProducerId != null) {
        let ProducerId = (childrenNameCodesArray.find((e: any) => (e.id == selectedProducerId)));

        if (ProducerId != null && ProducerId.productorPadreId != parentId) {          

          localStorage.setItem(
            "filters",
            JSON.stringify({
              producerId: null,
              parentProducerId: selectedParentProducerId,
              seasonId: selectedSeasonId,
              speciesId: selectedSpeciesId,
              varietyId: selectedVarietyId,
            })
          );

          localStorage.setItem("producerSearch", "");          

          setProducerSearch("");
          setSelectedProducerId(null);
          onProducerChange(null);          
        }
      }

      localStorage.removeItem("producer")
    }
    setReset(false);

  };

  const fetchEspeciesandVariety = async () => {

    const token = localStorage.getItem('token');
    const baseUrlenv = process.env.REACT_APP_API_URL;

    //const url = `${baseUrlenv}Productor`;
    let url = `${baseUrlenv}Miscelaneo/Relaciones?${selectedParentProducerId != null ? "ProductorPadreId=" + selectedParentProducerId : ''}${selectedProducerId != null ? '&ProductorId=' + selectedProducerId : ''}${selectedSeasonId != null ? '&TemporadaId=' + selectedSeasonId : ''}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Error en la llamada al API");
      }

      const data = await response.json();
      setRelations(data);
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  useEffect(() => {
    const localProfile = localStorage.getItem("perfil");
    if (localProfile && (localProfile === 'Administrador' || localProfile === 'Estandar') || localProfile === 'Productor') {
      let filters = localStorage.getItem('filters');
      //console.log('pasooo')
      if (filters) {
   
        // Parseamos el valor de filters de JSON a objeto
        const parsedFilters: FilterByInterface = JSON.parse(filters);

        // Verificamos individualmente si cada propiedad está nula y asignamos valores predeterminados
        const seasonId = parsedFilters.seasonId != null ? parsedFilters.seasonId : 5;  // Si 'seasonId' es null, asignamos 5
        const speciesId = parsedFilters.speciesId != null ? parsedFilters.speciesId : 3; // Si 'speciesId' es null, asignamos 3
        const parentProducerId = parsedFilters.parentProducerId != null ? parsedFilters.parentProducerId : null; // Si 'parentProducerId' es null, asignamos 2615
        const producerId = parsedFilters.producerId != null ? parsedFilters.producerId : null; // Si 'parentProducerId' es null, asignamos 2615
        let varietyId: number | null = parsedFilters.varietyId != null ? parsedFilters.varietyId : null;  // Si 'varietyId' es null, asignamos 1

        // Asignamos los valores al estado
        setSelectedSeasonId(seasonId);
        setSelectedSpeciesId(speciesId);
        if(localProfile != 'Productor'){
          setSelectedParentProducerId(parentProducerId);
        }
   
        
        if (varietyId == 1) {
          varietyId = null;
        }
       // console.log("Codigo hijo: ", producerId);

        setSelectedVarietyId(varietyId);
        setSelectedProducerId(producerId);

      } else {
        // Si no hay filtros en localStorage, asignamos valores predeterminados
        setSelectedSeasonId(5);
        setSelectedSpeciesId(3);
       
        //setSelectedParentProducerId(2615);
        //setSelectedVarietyId(4)

      }
    }

  }, []);

  useEffect(() => {
    fetchEspeciesandVariety();
  }, [selectedParentProducerId, selectedProducerId, selectedSeasonId, selectedSpeciesId, selectedVarietyId])

  const producersData: any = producers;
  const childrenData: any = children;
  const seasonsData: any = seasons;
  const speciesData: any = species;
  const varietiesData: any = varieties;

  let fatherProducerCodesArray: any = [];
  let childrenNameCodesArray: any = [];
  let seasonArray: any = [];
  let speciesArray: any = [];
  let varietiesArray: any = [{ id: null, nombre: 'Todas' }];

  if (producersData.lista) fatherProducerCodesArray = producersData.lista;
  if (childrenData.lista) {
    let childrenArray: any = [{ id: null, nombre: 'Todas' }];

    let array = childrenData.lista;

    childrenNameCodesArray.push(...childrenArray);
    childrenNameCodesArray.push(...array);
  }
  if (seasonsData) seasonArray = seasonsData;
  if (speciesData) speciesArray = speciesData;
  if (varietiesData) varietiesArray.push(...varietiesData);

  const handleReset = () => {

    const productoresIds = JSON.parse(localStorage.getItem("prod") || "[]");
    const localProfile = localStorage.getItem("perfil");
    if (productoresIds.length > 0) {
      if(localProfile!="Productor"){
        const firstProducerId = fatherProducerCodesArray[0]; // Asumimos que fatherProducerCodesArray tiene IDs
        setSelectedParentProducerId(firstProducerId);
      }
      else{
        const firstProducerId = productoresIds[0]; // Asumimos que fatherProducerCodesArray tiene IDs
        setSelectedParentProducerId(firstProducerId);
        onParentProducerChange(null);
        setSelectedProducerId(null);
        onProducerChange(null);
        setSelectedVarietyId(null);
        onVarietyChange(null);
    
        localStorage.removeItem("producer");
      }
     
    }
    else {
      setReset(true);
      setInputValue("");
      setSelectId(null);
      setSelectedParentProducerId(null);
      setSelectedProducerId(null);
      setSelectedSeasonId(5);
      setSelectedSpeciesId(3);
      setSelectedVarietyId(null);
      onParentProducerChange(null);
      onProducerChange(null);
      onSeasonChange(null);
      onSpeciesChange(null);
      onVarietyChange(null);
      //setSelectedProducerId(null);
      setSelectedParentProducerId(null);
      localStorage.removeItem("parentProducer");
      localStorage.removeItem("producer");
      //localStorage.removeItem("filters");

      localStorage.setItem(
        "filters",
        JSON.stringify({
          producerId: selectedProducerId,
          parentProducerId: selectedParentProducerId,
          seasonId: 5,
          speciesId: 3,
          varietyId: selectedVarietyId,
        })
      );


    }
  };
  const filteredSeasonArray = seasonArray.filter((season: any) =>
    season.nombre.toLowerCase().includes(seasonSearch.toLowerCase())
  );
  const filteredSpeciesArray = speciesArray.filter((species: any) =>
    species.nombre.toLowerCase().includes(speciesSearch.toLowerCase())
  );
  const filteredVarietiesArray = varietiesArray.filter((variety: any) =>
    variety.nombre.toLowerCase().includes(varietySearch.toLowerCase())
  );
  const filteredChildrenNameCodesArray = childrenNameCodesArray.filter((item: any) =>
    item.nombre.toLowerCase().includes(producerSearch.toLowerCase())
  );

  useEffect(() => {
    const productoresIds = JSON.parse(localStorage.getItem("prod") || "[]");
    const filter = JSON.parse(localStorage.getItem("filters") || "{}");
    
    // Si 'prod' no está vacío y fatherProducerCodesArray tiene elementos
    if (productoresIds.length > 0 && fatherProducerCodesArray.length > 0) {
      // Verifica si el primer productor en 'fatherProducerCodesArray' está en productoresIds
      const firstProducer = fatherProducerCodesArray[0]; // Asumiendo que fatherProducerCodesArray contiene objetos { id: X }
      if (filter.parentProducerId === null) {
      // Si el primer productor en fatherProducerCodesArray está en productoresIds
      if (productoresIds.includes(firstProducer.id)) {
        setSelectedParentProducerId(firstProducer.id);
      }
    }
    }
  }, [fatherProducerCodesArray]);
  return (
    <div className="flex items-center rounded-[2px] bg-bg-filtros w-full">
      <div className="flex flex-wrap items-center min-h-[40px]">
        <div className="h-3/4 flex items-center py-1 px-3 border-gray-300">
          <Dropdown
            reset={reset}
            onInputChange={(value: string) => handleInputChange('parentProducerSearch', value)}
            onSelectChange={(value: any) => {
              setSelectedParentProducerId(value);
              onParentProducerChange(value);
              handleSelectChange(value, true);
            }}
            name="nombre"
            description="Cód Padre-Productor"
            childrenOptions={fatherProducerCodesArray}
            relationOptions={[]}
            selectedValue={selectedParentProducerId}
            searchValue={parentProducerSearch}
            fieldName={`parentProducer`}
          />
        </div>
        <div className="h-[80%] w-[1px] bg-gray-300"></div>
        <div className="h-3/4 flex items-center py-1 px-3 border-gray-300">
          <Dropdown
            reset={reset}
            onInputChange={(value: string) => handleInputChange('producerSearch', value)}
            onSelectChange={(value: any) => {
              setSelectedProducerId(value);
              onProducerChange(value);
            }}
            name="nombre"
            description="Cód Hijo-Nombre"
            childrenOptions={filteredChildrenNameCodesArray}
            relationOptions={[]}
            selectedValue={selectedProducerId}
            searchValue={producerSearch}
            fieldName={`producer`}
          />
        </div>
        <div className="h-[80%] w-[1px] bg-gray-300"></div>
        <div className="h-3/4 flex items-center py-1 px-3 border-gray-300">
          <Dropdown
            reset={reset}
            onInputChange={(value: string) => handleInputChange('seasonSearch', value)}
            onSelectChange={(value: any) => {
              setSelectedSeasonId(value);
              onSeasonChange(value);
              //handleSelectChangeRelation();
              setReset(false);
            }}
            description="Temporada"
            name="nombre"
            childrenOptions={filteredSeasonArray}
            relationOptions={[]}
            selectedValue={selectedSeasonId}
            searchValue={seasonSearch}
            fieldName={`season`}
          />
        </div>
        <div className="h-[80%] w-[1px] bg-gray-300"></div>
        <div className="h-3/4 flex items-center py-1 px-3 border-gray-300">
          <Dropdown
            reset={reset}
            onInputChange={(value: string) => handleInputChange('speciesSearch', value)}
            onSelectChange={(value: any) => {
              setSelectedSpeciesId(value);
              onSpeciesChange(value);
              //handleSelectChangeRelation();
              setReset(false);
            }}
            description="Especie"
            name="nombre"
            childrenOptions={filteredSpeciesArray}
            relationOptions={relations?.especies.map((especie: Especie) => Number(especie.especieId))}
            selectedValue={selectedSpeciesId}
            searchValue={speciesSearch}
            fieldName={`species`}
          />
        </div>
        <div className="h-[80%] w-[1px] bg-gray-300"></div>
        <div className="h-3/4 flex items-center py-1 px-3">
          <Dropdown
            reset={reset}
            onInputChange={(value: string) => handleInputChange('varietySearch', value)}
            onSelectChange={(value: any) => {
              setSelectedVarietyId(value);
              onVarietyChange(value);
              //handleSelectChangeRelation();
              setReset(false);
            }}
            description="Variedad"
            name="nombre"
            childrenOptions={filteredVarietiesArray}
            relationOptions={relations?.especies.length != 0 ? relations?.especies.find((e: any) => e.especieId === selectedSpeciesId)?.variedades : []}
            selectedValue={selectedVarietyId}
            searchValue={varietySearch}
            fieldName={`variety`}
          />
        </div>
        <div className="h-3/4 flex items-center py-2 px-3 mr-6">
          <button className="rounded-full w-[79px] h-[30px] bg-[#FFF0F0] text-[#E36464] btn-reset-filter underline" onClick={handleReset}>Limpiar Filtros</button>
        </div>
      </div>
    </div>
  );
};

export default Filtros;
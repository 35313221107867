import { useState, useEffect } from "react";
import { FilterByInterface } from "../../modules/components/FilterBy/interfaces/filterByInterface";
import { fetchReceptions } from "../../services/receptions/fetchReceptions";
import { ReceptionDataInterface } from "../../modules/reception/interfaces/receptionDataInterface";

const useReceptions = (filterBy: FilterByInterface | null, isFiltersSynced: boolean) => {
  const [receptions, setReceptions] = useState<ReceptionDataInterface | null>(null);
  const [loadingReceptions, setLoading] = useState(true);
  const [errorReceptions, setError] = useState<string | null>(null);
  const [page, setPage] = useState(filterBy?.pageNumber || 1);
  interface FilterByInterface {
    pageNumber: number; 
    parentProducerId?: number | null;
    seasonId?: number | null;
    speciesId?: number | null;
    varietyId?: number | null;
    // Añadir otras propiedades según tu interfaz
  }
  useEffect(() => {
    const loadReceptionsData = async () => {
      // Solo ejecutamos si los filtros están sincronizados
      if (isFiltersSynced) {
       // console.log('Filtros sincronizados, obteniendo recepciones...');
        setLoading(true);
        setError(null); // Limpiar error antes de intentar la carga
  
        try {
          // Llamada a la API con los filtros actuales
          const data = await fetchReceptions(filterBy);
         setReceptions(data); // Guardar las recepciones obtenidas
        } catch (error: any) {
          setError(error.message); // Mostrar el error si la llamada falla
        } finally {
          setLoading(false); // Finalizar la carga
        }
      } 
    };
  
    loadReceptionsData();
  }, [filterBy, isFiltersSynced]);
   // Dependencias actualizadas

  return { receptions, loadingReceptions, errorReceptions, setPage };
};

export default useReceptions;

import { useState, useEffect } from "react";
import { fetchProcesses } from "../../services/processes/fetchProcesses";
import { FilterByInterface } from "../../modules/components/FilterBy/interfaces/filterByInterface";
import { ProcessDataInterface } from "../../modules/processes/intefaces/processDataInterface";

const useProcesses = (filterBy: FilterByInterface | null, isFiltersSynced: boolean) => {
  const [processes, setProcesses] = useState<ProcessDataInterface>();
  const [loadingProccess, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState<number>(filterBy?.pageNumber || 1); // Default pageNumber if filterBy is null

  useEffect(() => {
    const loadProcesses = async () => {      
      if (isFiltersSynced) {        
        setLoading(true);
        setError(null);
        try {
          const data = await fetchProcesses(filterBy);
          setProcesses(data); // Establecemos los procesos obtenidos
        } catch (error: any) {
          setError(error.message); // Establecemos el error en caso de fallo
        } finally {
          setLoading(false);
        }
      } 
    };

    loadProcesses(); // Llamamos a la función de carga

  }, [filterBy, isFiltersSynced]); // Dependemos de filterBy y isFiltersSynced

  return { processes, loadingProccess, error, setPage };
};

export default useProcesses;

import React, { useState } from 'react';
import Table from "../components/table";
import {PaginationInterface} from "../components/paginate/interfaces/paginationInterface";

interface ConsolidatedReceptionData {
  temporada: string;
  codigo: string;
  nombreProductor: string;
  especie: string;
  variedad: string;
  centro: string;
  fechaRecepcion: string;
  guia: string;
  partida: string;
  cantidadEnvases: number;
  recepcionadoKg: number;
  resultadoRecepcion: number;
}

type columType = 'string' | 'number' | 'boolean';
interface Column {
  column_name: string;
  name: string;
  type: columType;
}

const Reports: React.FC = () => {


  const [pagination, setPagination] = useState<PaginationInterface | null>(null);

  const [data, setData] = useState<ConsolidatedReceptionData[]>([
    {
      temporada: 'A000',
      codigo: '1234',
      nombreProductor: 'Agrícola David del Curto SPA',
      especie: 'Especie',
      variedad: 'Variedad',
      centro: 'Centro',
      fechaRecepcion: 'dd/mm/aaaa',
      guia: '00000',
      partida: '00000',
      cantidadEnvases: 12345,
      recepcionadoKg: 12345,
      resultadoRecepcion: 12345
    },
  ]);

  const columns:Column[] = [
    {column_name:'temporada', name: 'Temporada', type: "string"},
    {column_name:'codigo', name: 'Codigo', type: "string"},
    {column_name:'nombreProductor', name: 'Nombre Productor', type: "string"},
    {column_name:'especie', name: 'Especie', type: "string"},
    {column_name:'variedad', name: 'Variedad', type: "string"},
    {column_name:'centro', name: 'Centro', type: "string"},
    {column_name:'fechaRecepcion', name: 'Fecha Recepcion', type: "string"},
    {column_name:'guia', name: 'Guia', type: "string"},
    {column_name:'partida', name: 'Partida', type: "string"},
    {column_name:'cantidadEnvases', name: 'Cantidad Envases', type: "string"},
    {column_name:'recepcionadoKg', name: 'Recepcionado (KG)', type: "string"},
    {column_name:'resultadoRecepcion', name: 'Resultado Recepcion', type: "string"},
  ];

    const handleFilterChange = (value: string) => {
        //console.log('Filter changed:', value);
    };

    const handleExport = () => {
        //console.log('Exportando datos a Excel...');
    };

    return (
      <div className="w-full p-6">
        <div className={'mb-[22px]'}>
          <h1 className={'font-[400] text-[24px]'}>Visualizador de consolidado de recepciones (KG)</h1>
          <p className={'text-[12px] pt-[8px]'}>Visualiza y descarga tu archivo.</p>
        </div>
        <hr/>
        <div>
          <div
            className={'rounded-[6px] flex justify-center align-bottom bg-[linear-gradient(90deg,_#01AAEF_0.72%,_#2C2D65_100%)] px-[21px] pt-[31px] h-[155px]'}>
              <div className={'rounded-[2px] bg-white w-full flex items-center justify-between p-[20px]'}>
                <div>
                  <h1 className={'font-[400] text-[24px]'}>Consolidado Recepciones (KG)</h1>
                  <p className={'text-[12px] pt-[8px]'}>Codigo DDC - Nombre Productor - Temporada</p>
                </div>
                <button className="w-[196px] h-[42px] bg-[#00C7B1] rounded-[6px] text-white">Consolidado Excel</button>
              </div>
          </div>
          <Table columns={columns} data={data} pagination={pagination}/>
        </div>
      </div>
    );
}

export default Reports;
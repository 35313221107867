import { FilterByInterface } from "../../modules/components/FilterBy/interfaces/filterByInterface";

export const fetchReceptions = async (filterBy: FilterByInterface | null) => {
  const token = localStorage.getItem('token');
  const perfil = localStorage.getItem('perfil');
  //console.log('paso')
  if (!token) {
    console.error("No se encontró el token de autenticación.");
    throw new Error("Token de autenticación no encontrado.");
  }

  const baseUrlenv = process.env.REACT_APP_API_URL;
  const url = `${baseUrlenv}Recepciones/Listar`;

  let filters = localStorage.getItem('filters');
  let prod = localStorage.getItem('prod');
  let parsedProd = prod ? JSON.parse(prod) : [];
  
  if (!filters) {
    console.warn("No se encontraron filtros en localStorage, se usarán los filtros por defecto.");
  }

  // Establecer valores predeterminados para las propiedades faltantes
  let parsedFilters: FilterByInterface = {
    pageNumber: 1, // Página inicial
    pageSize: 10,  // Tamaño de página por defecto
    paginationFilters: [], // Filtros de paginación vacíos por defecto
    parentProducerId: null,
    seasonId: null,
    speciesId: null,
    varietyId: null,
  };

  // Si existen filtros en localStorage, parsearlos
  if (filters) {
    try {
      parsedFilters = JSON.parse(filters);
    } catch (error) {
      console.error("Error al parsear los filtros de localStorage:", error);
      throw new Error("No se pudieron procesar los filtros.");
    }
  }
  

  // Crear el cuerpo de la solicitud
  const body = {
    pagina: filterBy?.pageNumber || 1,
    cantidadRegistros: filterBy?.pageSize || 10,
    paginationFilters: filterBy?.paginationFilters, // Filtros de paginación
  };
 if(parsedProd.length>0 || perfil != "Productor"){ 
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      console.error("Error en la solicitud:", errorMessage);
      throw new Error("Error en la solicitud de la API.");
    }

    const data = await response.json();
    return data; // Retorna los datos obtenidos de la API
  } catch (error) {
    console.error("Error:", error);
    throw error; // Lanza el error para que lo maneje el componente que llama a esta función
  }
}
};

import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { toast } from 'react-toastify';
import {callApi} from "../src/services/utilservices";
interface CustomJwtPayload {
  ID: string;
  Nombre: string;
  Correo: string;
  Telefono: string;
  Rut: string;
  Perfil: string;
  IdentificadorToken: string;
  exp: number;
  roles:string;
}

interface AuthContextType {
  user: CustomJwtPayload | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  error: string;
  hasRole: (roles: string[]) => boolean;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<CustomJwtPayload | null>(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  // Función para verificar el token y establecer el usuario
  const checkToken = () => {
    const token = localStorage.getItem('token'); 
    if (token) {
      const decodedToken = jwtDecode<CustomJwtPayload>(token);
       // Convertir el tiempo de expiración a milisegundos
      const expirationTime = decodedToken.exp * 1000; // Expiración en milisegundos
      const currentTime = Date.now(); // Tiempo actual en milisegund
     
     
      if (currentTime < expirationTime) {
        // Token aún es válido
        setUser(decodedToken);
       
      } else {
        localStorage.removeItem('token');
        setUser(null); 
       /* toast.error("Su sesión ha expirado. Por favor, inicie sesión nuevamente.", {
          position: "top-center",
        });*/
        //navigate('/login');
      }
    } else {
      //console.log("No hay token disponible.");
     
      //navigate('/login');
    }
  };
  const formatDate = (dateString:any) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses comienzan desde 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  useEffect(() => {
    checkToken(); // Verifica el token al cargar el componente
  }, []);

  const login = async (email: string, password: string) => {
    try {
      const response = await fetch(`${apiUrl}Usuarios/Login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, clave: password }),
      });
     //console.log(response)
      if (!response.ok) {
        throw new Error('Credenciales incorrectas');
      }

      const { data } = await response.json();
      localStorage.setItem('token', data.accesToken);
     

      //console.log("Token guardado en localStorage:", data.accesToken); // Log del token guardado

      // Verificar y establecer el usuario después de iniciar sesión
      checkToken(); // Actualiza el usuario llamando a la función checkToken
      navigate('/dashboard');
    } catch (error: any) {
      setError(error.message);
      localStorage.removeItem('token');
      setUser(null); // Limpia el usuario en caso de error
      //console.log("Error en login:", error.message); // Log del error
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('rol');
    localStorage.removeItem('nombre');
    localStorage.removeItem('filter');
    localStorage.removeItem('parentProducerSearch');
    localStorage.removeItem('parentProducer');
    localStorage.removeItem('season');
    localStorage.removeItem('species');
    setUser(null);
    navigate('/login');
   // console.log("Usuario deslogueado."); // Log al cerrar sesión
  };

  const hasRole = (roles: string[]) => {
    if (!user) return false;
    return roles.includes(user.Perfil);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, error, hasRole }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

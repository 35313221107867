import {callApi, handleDownloadBase64} from "../utilservices";
import {ExportExcelProcessesInterface} from "./interfaces/exportExcelProcessesInterface";

export const fetchExportProcesses= async (filters:ExportExcelProcessesInterface[]) => {
 let url=`Proceso/Consolidado`;
   try {
    const response = await callApi({
      url: url,
      method: 'POST',
      data: {
        paginationFilters: filters,
      },
      responseType: 'json',
    });

    return response;

  } catch (error) {
    console.error('Error al descargar el archivo:', error);
  } finally {
    // setLoading(false);
  }
};
import { useState, useEffect } from "react";
import {fetchSeasons} from "../../services/seasons/fetchSeasons";

const useSeasons = (initialPage = 1, pageSize = 5, filterBy = "", searchText = "") => {
  const [seasons, setSeasons] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(initialPage);

  useEffect(() => {
    const loadSeasons = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await fetchSeasons(page, pageSize, filterBy, searchText);
        setSeasons(data);
      } catch (error:any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    loadSeasons();
  }, []);

  return { seasons, loading, error, setPage };
};

export default useSeasons;

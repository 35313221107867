import React, { useEffect, useState, useRef } from "react";
import useData from "./userData";
import { useNavigate, useParams } from 'react-router-dom';
import { callApi } from '../../../services/utilservices';
import { useSnackbar } from '../../../context/SnackbarContext';
import axios from 'axios';
const UserData = () => {
  const { data, handleData } = useData();
  const [rutError, setRutError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();
  const [formValues, setFormValues] = useState({
    id: 0,
    nombre: "",
    rut: "",
    telefono: "",
    email: "",
    perfil: 0,
    contrasena: "",
    confirmaContrasena: "",
    productores: "",

  });
  const rutInputRef = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState(false); 
  const [isValid, setIsValid] = useState(false);
  const [phone, setPhone] = useState('');
  // Ejecuta `handleData()` una sola vez al montar el componente
  useEffect(() => {
    handleData();
  }, []); // Solo se ejecuta una vez al montar

  
  useEffect(() => {
    if (data) {
      setFormValues({
        id: data.id  || 0,
        nombre: data.nombre || "",
        rut: data.rut || "",
        telefono: data.telefono || "",
        email: data.correo || "",
        perfil: data.perfil || 0,
        contrasena: "",
        confirmaContrasena: "",
        productores: "",
      });
    }
  }, [data]); // Ejecuta solo cuando `data` cambia
  const handleClose = () => {
    navigate(`/administracion/usuarios/`);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const validateRUT = (rut: any) => {
    const cleanRUT = rut.replace(/[^0-9kK]/g, '');

    
    

    // Bloquear RUTs con caracteres repetidos como 11.111.111-1
    const body = cleanRUT.slice(0, -1);
    if (/^(\d)\1+$/.test(body)) return false; // Verifica si todos los caracteres son iguales
    if (!/^\d+$/.test(body) || /^[1-9]$/.test(body)) return false;
    if (cleanRUT.length < 2) return false;

    const verifier = cleanRUT.slice(-1).toUpperCase();
    if (!/^\d+$/.test(body)) return false;

    let sum = 0;
    let multiplier = 2;

    for (let i = body.length - 1; i >= 0; i--) {
        sum += parseInt(body.charAt(i), 10) * multiplier;
        multiplier = multiplier === 7 ? 2 : multiplier + 1;
    }

    const remainder = sum % 11;
    const calculatedVerifier = remainder === 0 ? '0' : remainder === 1 ? 'K' : (11 - remainder).toString();

    return calculatedVerifier === verifier;
};

  const formatRUT = (rut: string) => {
    const cleanRUT = rut.replace(/[^0-9kK]/g, '');
    if (!cleanRUT) return '';

    const body = cleanRUT.slice(0, -1);
    const verifier = cleanRUT.slice(-1).toUpperCase(); // Asegúrate de que el verificador esté en mayúsculas

    // Formato del cuerpo con puntos
    const formattedBody = body.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return `${formattedBody}-${verifier}`;
  };

  const handleRUTChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase(); // Convertir el valor a mayúsculas
    const formattedRUT = formatRUT(value);
    setFormValues((prevValues) => ({
      ...prevValues,
      rut: formattedRUT,
    }));

    // Validar el RUT
    if (validateRUT(formattedRUT)) {
      setRutError(''); // Limpiar el mensaje de error si es válido
    } else {
      setRutError('RUT no válido'); // Mensaje de error si no es válido
    }
  };
  const hashPassword = (password:any) => {
    return CryptoJS.SHA256(password).toString(); // Hash SHA256
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    //console.log(name, email, rut, phone, selectedProducer);

    // Verifica si todos los campos requeridos están completos
    if (!formValues.nombre|| !formValues.email || !formValues.telefono || !formValues.rut ) {
      showMessage('Completa todos los campos', 'error'); 
      return;
    }
    
    const userPayload = {
      
        id: formValues.id,
        nombre: formValues.nombre,
        correo: formValues.email,
        telefono: formValues.telefono,
        rut: formValues.rut,
        perfil: formValues.perfil,
        contrasena: formValues.contrasena ? hashPassword(formValues.contrasena) : "", // Encriptar si no está vacía
        confirmaContrasena: formValues.confirmaContrasena ? hashPassword(formValues.confirmaContrasena) : "", 
        productores: [],
       
      
    };

    try {
      const response = await callApi({
        url: `Usuarios`, // Cambia esto a la ruta correcta para editar el usuario
        method: 'PUT',
        data: userPayload,
      });
      
   
     if (response) {
      showMessage('Usuario editado exitosamente', 'success'); 
    
      setTimeout(() => {
        navigate('/perfil');
      }, 2000);
     }
     else{
      showMessage(response.mensaje, 'error'); 
     }
      
    
    } catch (error) {
      if (axios.isAxiosError(error)) {

        if (error.response) {
            const errorResponse = error.response.data; 
            showMessage(errorResponse.mensaje || "Ocurrió un error inesperado.", 'error'); 
           
           
        } else {
            
            showMessage("Ha ocurrido un error al editar, intenta de nuevo.", 'error'); 
            
        }
      }
    }
  };
  useEffect(() => {
    //console.log(formValues.rut)
    if (formValues.rut && !validateRUT(formValues.rut)) {
      setRutError('RUT no válido');
    }
    else{
      setRutError('');
    }
    setIsValid(true)
  }, [formValues.rut]);


  const handleRUTBlur = () => {
    // Validar al perder el foco
    if (!validateRUT(formValues.rut)) {
        setRutError('RUT no válido');
        rutInputRef.current?.focus(); // Forzar el foco de nuevo en el input
    }
  };

  const handlePhoneChange = (e: any) => {
    const input = e.target.value;
  
    // Validar que no sea una secuencia repetida (ej. 111111111, 222222222)
    if (/^(\d)\1{8}$/.test(input)) {
      showMessage('El número de teléfono no puede ser una secuencia repetida', 'error');
      setIsValid(false);
      //console.log(isValid)
      return;
    }
  
    // Permitir solo números y limitar a 9 caracteres
    if (/^\d{0,9}$/.test(input)) {
      setFormValues((prevValues) => ({
        ...prevValues,
        telefono: input, // Actualizar el teléfono dentro de `formValues`
      }));
      setPhoneError(''); 
      setIsValid(true);
    }
  };
  return (
    <div>
      <div>
      <form onSubmit={handleSubmit} className="">
        <h3 className="text-[24px] font-normal mb-[13px]">Datos del Usuario</h3>
        <hr />
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
         
          <div>
            <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">Nombre</label>
            <input
              type="text"
              name="nombre"
              value={formValues.nombre}
              onChange={handleChange}
              className="outline-0 w-full h-[42px] px-3 py-[9px] border border-blue-500 rounded-[6px] bg-gray-100"
            />
          </div>
          <div>
            <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">RUT</label>
            <input
              type="text"
              name="rut"
              value={formValues.rut}
              ref={rutInputRef}
              maxLength={12}
              onBlur={handleRUTBlur}
              onChange={handleRUTChange}  // Cambiar a handleRUTChange para validación
              className="outline-0 w-full h-[42px] px-3 py-[9px] border border-gray-300 rounded-[6px] bg-gray-100"
            />
            {rutError && <p className="text-red-500 text-sm">{rutError}</p>}
          </div>
          <div>
            <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">Teléfono</label>
            <div className="flex">
              <select
                name="codigo"
                onChange={handleChange}
                className="h-[42px] px-3 py-2 border border-gray-300 rounded-l-[6px] bg-gray-100"
              >
                <option value="+56">+56</option>
              </select>
              <input
                type="text"
                name="telefono"
                value={formValues.telefono}
                onChange={handlePhoneChange}
                className={`outline-0 w-full h-[42px] px-3 py-[9px] border rounded-[6px] ${!isValid ? 'border-red-500' : ''}`}
                placeholder="Ingresa tu teléfono"
              />
            </div>
          </div>
          <div>
            <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">E-mail</label>
            <input
              type="email"
              name="email"
              value={formValues.email}
              disabled
              className="outline-0 w-full h-[42px] px-3 py-[9px] border border-gray-300 rounded-[6px] bg-gray-100"
            />
          </div>
          <div>
            <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">Tipo de Perfil</label>
            <select
              name="tipoPerfil"
              value={formValues.perfil}
              disabled
              className="outline-0 w-full h-[42px] px-3 py-[9px] border border-gray-300 rounded-[6px] bg-gray-100"
            >
              <option value="2">Estandar</option>
              <option value="1">Administrador</option>
              <option value="3">Productor</option>
            </select>
          </div>
        
        </div>

        <div className="mt-6 text-right">
        <button
                type="button"
                onClick={handleClose}
                className="px-4 py-2 mr-4 text-blue-500 border border-blue-500 rounded hover:bg-blue-50 w-[196px] h-[42px]"
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 w-[196px] h-[42px]"
                disabled={!isValid} 
              >
                Editar
              </button>
        </div>
        </form>
      </div>
    </div>
  );
};

export default UserData;

import CustomSelect from "../select";
import { useState } from "react";
import {FilterByPropsInterface} from "./interfaces/filterByPropsInterface";

const FilterBy = ({ childrenOptions, onFilterChange }:FilterByPropsInterface) => {

  const [selectedFilter, setSelectedFilter] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");

  const updateFilterData = (newFilter: string, newSearch: string) => {
    const filterData = { selectedFilter: newFilter, searchText: newSearch };
    onFilterChange(filterData);
  };

  const handleSelectFilterChange = (optionSelected: string) => {
    setSelectedFilter(optionSelected);
    //console.log(optionSelected);
    if(optionSelected == ''){
      setSearchText("");
      updateFilterData(optionSelected, "");
    } 
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      updateFilterData(selectedFilter, searchText);
    }
  };

  return (
    <div className={`w-full filter-by`}>
        <div
          className="flex w-full h-[55px] p-[10px] bg-white mr-[33px] rounded-[5px] shadow-[0px_8px_13px_-3px_rgba(0,0,0,0.07)]">
          <div>
          <CustomSelect
            description=""
            childrenOptions={childrenOptions}
            value={selectedFilter}
            onChange={handleSelectFilterChange}
          />
          </div>
          <div className="h-full w-[1px] bg-gray-300 mr-[52px]"></div>
          <div className="h-full w-full">
          <input
            className="h-full w-full outline-0"
            placeholder={'Buscar aquí...'}
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyUp={handleKeyUp}
          />
          </div>
        </div>
      {/*<button*/}
      {/*  onClick={handleSearch}*/}
      {/*  className="flex justify-center items-center w-[196px] h-[42px] bg-[#00C7B1] rounded-[6px] text-white ml-3"*/}
      {/*>*/}
      {/*  Buscar*/}
      {/*</button>*/}
    </div>
  )
}

export default FilterBy;
import React, { useState } from 'react';

const CreateProducer = () => {
  return(
    <div className="w-full p-6">
      <div className="max-w-[1009px] min-h-[553px] h-full w-full px-[36px] pt-[26px] pb-[40px] shadow rounded-lg">
        <h2 className="text-2xl font-semibold text-[#111928] page-title ">Nuevo Productor</h2>
        <p className="text-gray-500 mt-2 page-subtitle">
         Gestiona la lista de usuarios Productores.
        </p>

        <div className={'mt-[42px]'}>
          <form className="">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
              <div>
                <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">Nombre Completo</label>
                <input
                  type="text"
                  placeholder="Ingresa el nombre y apellido del usuario"
                  className="outline-0 w-full h-[42px] px-3 py-[9px] border border-gray-300 rounded-[6px]"
                />
              </div>
              <div>
                <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">E-mail</label>
                <input
                  type="email"
                  placeholder="Ingresa la dirección de correo electrónico"
                  className="outline-0 w-full h-[42px] px-3 py-[9px] border border-gray-300 rounded-[6px]"
                />
              </div>
              <div>
                <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">Número celular</label>
                <div className="flex">
                  <select
                    className={`h-[42px] px-3 py-2 border rounded-l`}>
                    <option value="+54">+54</option>
                  </select>
                  <input
                    type="text"
                    value="+00 0000 0000"
                    className={`outline-0 w-full h-[42px] px-3 py-[9px] border rounded-[6px]`}
                  />
                </div>
              </div>
              <div>
                <label className="block mb-2.5 roboto-regular text-[16px] text-[#292D32]">RUT</label>
                <input
                  type="text"
                  placeholder="Ingresa el RUT del usuario"
                  className="outline-0 w-full h-[42px] px-3 py-[9px] border border-gray-300 rounded-[6px]"
                />
              </div>
            </div>

            <div className="flex justify-end mt-6">
              <button
                type="button"
                className="px-4 py-2 mr-4 text-blue-500 border border-blue-500 rounded hover:bg-blue-50 w-[196px] h-[42px]"
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 w-[196px] h-[42px]"
              >
                Crear
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateProducer;
import { useState, useEffect, useCallback } from 'react';
import { fetchQualityReport } from "../../services/receptions/fetchQualityReport";
import {handleDownloadPDF2,handleDownloadPDF} from "../../services/utilservices";
import {fetchPTIReport} from "../../services/receptions/fetchPTIReport";
import { toast, ToastContainer } from 'react-toastify';

export const useQualityReport = () => {
  const [dataQualityReport, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const getQualityReport = useCallback(async (numeroPartida: number, specieId: number|null = null, especieNombre: string, variedad: string, codigo:number) => {
    try {
      setLoading(true);
      let response: any = null;
 
      if(specieId === 3) {
        
        const ptiConsultalote = await fetchPTIReport(numeroPartida);     
        response = ptiConsultalote //!= null ? ptiConsultalote[0].PDF : null;        
      } else {
        response = await fetchQualityReport(numeroPartida);
      }
      const now = new Date();
      const date = now.toLocaleDateString();
      const hora = now.toLocaleTimeString();
      const fileNameParts = [
        codigo, // Se incluye si no es null ni undefined
        especieNombre, // Se incluye si no es null ni undefined
        variedad, // Se incluye si no es null ni undefined
        numeroPartida, // Se incluye si no es null ni undefined
        date, // Siempre incluido
      ];
      
      // Filtra los valores nulos o indefinidos y los une con guiones bajos
      const fileName = fileNameParts
        .filter((part) => part != null && part !== "") // Filtrar null, undefined y cadenas vacías
        .join("_") + ".pdf";
      if (response) {
        // Lógica específica para cada tipo de informe.
        if (specieId === 3) {
            handleDownloadPDF2(response, fileName,);
        }
        else {
         
          handleDownloadPDF(response, fileName,);
        }
      } else {     
        toast.warning("Informe2 no encontrado", { position: "top-center" });
        console.error("Error en la operación:", response?.data.mensaje);
      }
      setData(response);
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, []);

  return { dataQualityReport, loading, error, getQualityReport };
};

// Snackbar.tsx
import React from 'react';
import { ExclamationCircleIcon, CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid';
import './snackbar.css';
interface SnackbarProps {
  message: string;
  type: 'success' | 'error' | 'info';
  isOpen: boolean;
  onClose: () => void;
}

const Snackbar: React.FC<SnackbarProps> = ({ message, type, isOpen, onClose }) => {
  if (!isOpen) return null;

  let icon;
  switch (type) {
    case 'success':
      icon = <CheckCircleIcon className="w-6 h-6 text-green-500" />;
      break;
    case 'error':
      icon = <ExclamationCircleIcon className="w-6 h-6 text-red-500" />;
      break;
    case 'info':
      icon = <InformationCircleIcon className="w-6 h-6 text-blue-500" />;
      break;
    default:
      icon = null;
  }

  return (
    <div className="fixed top-5 right-5 z-50 flex items-center p-4 white_mensaje bg-blue-500_mensaje rounded_mensaje shadow-lg_mensaje">
      <div className="flex items-center">
        {icon}
        <span className="ml-2">{message}</span>
      </div>
      <button className="ml-4 white_mensaje" onClick={onClose}>
        ✖
      </button>
    </div>
  );
};

export default Snackbar;

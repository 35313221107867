import { ChevronDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { CustomSelectInterface } from "./intefaces/customSelectInterface";

export default function CustomSelect({ description, childrenOptions, value, onChange }: CustomSelectInterface) {
  return (
    <div className="w-[250px] mx-3">
      <p className="text-base font-light text-white">{description}</p>
      <div className="relative">
        <select
          value={value}
          onChange={(e) => onChange && onChange(e.target.value)}
          className={clsx(
            'block w-full appearance-none rounded-md border-none bg-white py-2 px-3 text-sm/6 text-font-blue-gray',
            'focus:outline-none'
          )}
        >
          {childrenOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <ChevronDownIcon className="pointer-events-none absolute top-2.5 right-2.5 size-4 text-font-blue-gray" aria-hidden="true" />
      </div>
    </div>
  );
}

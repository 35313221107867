import React, { useState } from 'react';
import UserData from "./user-data";
import UserPassword from "./user-password";


const Profile = () => {

  const [selectedTab, setSelectedTab] = useState('datos');


  return (
    <div className="mx-auto p-6 bg-white w-full">
      <h2 className="text-2xl font-semibold text-gray-800 page-title">Perfil</h2>
      <p className="text-gray-500 mt-2 page-subtitle">Consulta tu información y cambia tu clave de acceso.</p>
      <div className="max-w-[910px] w-full mt-[44px]">
        <div className="mt-6">
          <ul className="flex border-b">
            <li className="mr-1">
              <a onClick={() => setSelectedTab('datos')}
                 className={`cursor-pointer bg-white inline-block py-2 px-4 font-medium roboto-regular ${(selectedTab === 'datos') ? 'border-b-[4px] border-b-[#003DA5] text-[#003DA5]' : ''}`}>
                Mis datos
              </a>
            </li>
            <li className="mr-1">
              <a onClick={() => setSelectedTab('password')}
                 className={`cursor-pointer inline-block py-2 px-4 roboto-regular font-medium ${(selectedTab === 'password') ? 'border-b-[4px] border-b-[#003DA5] text-[#003DA5]' : ''}`}>
                Actualizar Contraseña
              </a>
            </li>
          </ul>
        </div>

        <div className="mt-[46px] px-[40px] pt-[24px] pb-[40px] border border-[#E2E8F0] max-w-[910px] w-full">
          {selectedTab === 'datos' && (
            <UserData/>
          )}

          {selectedTab === 'password' && (
            <UserPassword/>
          )}
        </div>
      </div>
    </div>
  );
}

export default Profile;
export const fetchChildProducers = async (pageNumber = 1, pageSize = 3, filtrarPor = "", textoBusqueda = "", childId:number | null) => {

  const token = localStorage.getItem('token');
  const baseUrlenv = process.env.REACT_APP_API_URL;
  const season = localStorage.getItem('season'); 
  let idseason=5;
  if(season=="2024-2025"){
   idseason=5;
  }
  else{
    idseason=1;
  }
  //const url = `${baseUrlenv}Productor`;
  let url = `${baseUrlenv}Productor/${pageNumber}/${pageSize}/productorPadreId/${childId}/${idseason}`;
  
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Error en la llamada al API");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

import { useState } from "react";
import { Field, Input } from "@headlessui/react";
import LogoDDC from "../../assets/Logo-DDC";
import Widget from "../components/widget";
import DDCButton from "../components/buttons";
import clsx from 'clsx';
import { callApi } from '../../services/utilservices';
import { toast, ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import { useNavigate } from 'react-router-dom';

const AccessRequest = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false); // Estado de carga
    const navigate = useNavigate();

    const handleRecoveryClick = async () => {
       // console.log(email)
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/Notificacion/notificacionCreacionUsuario`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Asegúrate de establecer el tipo de contenido
                },
                body: JSON.stringify({ correoUsuario: email }),
            });
        
            const result = await response.json(); // Convierte la respuesta a JSON
        
            if (response.ok && result.satisfactorio) {
                toast.success("En breve le mandamos un email con sus nuevas credenciales", { position: "top-center" });
                setTimeout(() => {
                    navigate('/login');
                }, 1000);
            } else {
                toast.error("Error al enviar el email", { position: "top-center" });
            }
        } catch (error) {
            console.error("Error en la llamada a la API:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-container">
            <ToastContainer />
            <Widget classNameContainer="Widget rounded-md bg-white w-fit self-center">
                <div className="flex flex-col justify-center max-w-sm">
                    <LogoDDC className="mt-4 mb-10 self-center"/>
                    <h3 className="font-semibold text-2xl mb-1 mx-3 dev-font-f-titi-web">Recuperar el acceso</h3>
                    <p className="text-font-blue-gray text-sm mb-8 mx-3 dev-font-f-roboto">
                        Te enviaremos un link a tu dirección de email para corroborar que eres tú el que intenta ingresar.
                    </p>
                    <Field>
                        <Input
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className={clsx(
                                'block w-full border border-border-gray rounded-md bg-white/5 py-1.5 px-3 text-sm/6 h-11 mb-5',
                                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:border-input-focus-color'
                            )}
                        />
                    </Field>
                    <DDCButton 
                        click_action={handleRecoveryClick}
                        b_text={
                            loading ? (
                                <span className="flex items-center">
                                    <span className="loader"></span> {/* Asegúrate de tener la clase 'loader' en tu CSS */}
                                    <span className="ml-2">Cargando...</span>
                                </span>
                            ) : (
                                "Recuperar cuenta"
                            )
                        }
                        disabled={loading} // Deshabilitar el botón si está cargando
                    />
                    <span className="text-base self-center mt-4 dev-font-f-roboto">
                        ¿Tienes tu acceso? <a href="/login" className="text-ddc-blue">Iniciar sesión</a>
                    </span>
                    <span className="text-base self-center dev-font-f-roboto">
                        ¿Aún no tienes tu acceso? <a href="/recovery" className="text-ddc-blue">Solicítalo aquí</a>
                    </span>
                    <span className="text-sm text-center text-gray-500">versión beta 1.0.0</span>
                </div>
            </Widget>
        </div>
    );
}

export default AccessRequest;

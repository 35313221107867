import React, { useState, useEffect } from 'react';
import CustomSelect from "../../components/select";
import { callApi } from '../../../services/utilservices';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import { useSnackbar } from '../../../context/SnackbarContext';
import Paginate from "../../components/paginate"; // Importar el componente de paginación
import {ReactComponent as Borrar} from "../../../assets/icons/borrar.svg";
import {ReactComponent as Editar} from "../../../assets/icons/editar.svg";
import { CogIcon  } from "@heroicons/react/20/solid";
import 'react-toastify/dist/ReactToastify.css';
interface UserData {
  id: number; 
  nombre: string;
  correo: string;
  tipoPerfil: string;
}

const ListUser: React.FC = () => {
  const [data, setData] = useState<UserData[]>([]);
  const columns = ['Nombre', 'Email', 'Tipo Perfil', 'Acciones'];
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>("1");
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const { showMessage } = useSnackbar();
  const [loading, setLoading] = useState(false); 
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiToken = localStorage.getItem("token");

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await callApi({
        url: `Usuarios/${currentPage}/${itemsPerPage}` + 
             (selectedFilter && selectedFilter !== '' ? `/${selectedFilter}` : '/null') + 
             (searchText && searchText !== '' ? `/${searchText}` : '/null'),
        method: 'GET',
      });
      const { total, lista } = result;

      setTotalCount(total ?? 0);
      setData(Array.isArray(lista) ? lista : []);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage, selectedFilter]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setItemsPerPage(newPageSize);
    setCurrentPage(1); // Reiniciar a la primera página al cambiar el tamaño
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchData();
  };
  const handleDelete = (userId: number) => {
    // Toast para confirmar la eliminación
    const confirmToastId = toast.info(
      <div>
        <span>¿Estás seguro de que deseas eliminar este usuario?</span>
        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}>
          <button
            onClick={() => handleDeleteConfirmed(userId, confirmToastId.toString())} // Asegúrate de convertir confirmToastId a string
            style={{ color: 'red', marginLeft: '5px' }}
          >
            Sí, eliminar
          </button>
          <button
            onClick={() => toast.dismiss(confirmToastId)}
            style={{ marginLeft: '5px' }}
          >
            Cancelar
          </button>
        </div>
      </div>,
      {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      }
    );
  };


  
  const handleDeleteConfirmed = async (userId: number, confirmToastId: string) => {
    toast.dismiss(confirmToastId); // Cerrar el toast de confirmación
    setLoading(true); 
    try {
      const response = await callApi({
        url: `Usuarios`,
        method: 'DELETE',
        data: { id: userId }
      });
      if(response){
       fetchData(); 

       showMessage('Usuario eliminado exitosamente', 'success'); 
      
     }
    } catch (error) {
      showMessage('Error al eliminar el usuario', 'error'); 
      //toast.error("Error al eliminar el usuario.", { position: "top-center" });
    }
    finally {
     
      setLoading(false); // Desactivamos el spinner una vez la petición esté completa
     
    }
  };

  const handleAddUser = () => {
    navigate('/administracion/usuarios/crear');
  };
  const handleEdit = (userId: number) => {
    navigate(`/administracion/usuarios/editar/${userId}`);
  };
  return (
    <div className="w-full p-6">
      <div className={'mb-[22px]'}>
        <h1 className={'page-title'}>Administración de Usuarios</h1>
        <p className={'text-gray-500 page-subtitle pt-[8px]'}>Gestiona y organiza la información de los usuarios.</p>
      </div>
      <hr />
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20 z-50">
          <div className="text-white flex items-center space-x-3">
            <span className="text-xl">Procesando...</span>
          </div>
        </div>
      )}
      <div className="flex w-full flex-col  mb-[15px] mt-[34px]">
        <p className="text-xs text-gray-500 mb-2">
          Paso 1: Escoge un filtro. Paso 2: Ingresa el texto a buscar. Paso 3: Haz clic en "Buscar".
        </p>
        <div className="flex w-full items-center">
          <div className="flex w-full h-[55px] p-[10px] bg-white mr-[33px] rounded-[5px] shadow-[0px_8px_13px_-3px_rgba(0,0,0,0.07)]">
            <CustomSelect
              description=""
              childrenOptions={[
                { label: "Filtrar Por:", value: "" },
                { label: "Nombre", value: "nombre" },
                { label: "Correo", value: "correo" },
                { label: "Tipo Perfil", value: "tipoPerfil" }
              ]}
              onChange={setSelectedFilter}
            />
            <div className="h-full w-[1px] bg-gray-300 mr-[52px]"></div>
            <input
              className="h-full w-full outline-0"
              placeholder={'Buscar aquí...'}
              type="text"
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <button
            onClick={handleSearch}
            className="flex justify-center items-center w-[196px] h-[42px] bg-[#00C7B1] rounded-[6px] text-white ml-3"
          >
            Buscar
          </button>
          <button
            onClick={handleAddUser}
            className="w-[196px] h-[42px] bg-[#00C7B1] rounded-[6px] text-white ml-3"
          >
            Añadir Usuario
          </button>
        </div>
      </div>

      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            {columns.map((column, index) => (
              <th key={index} className="py-2 px-4 border-b text-left text-gray-600 w-auto text-center">{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((item) => (
              <tr key={item.id}>
                <td className="py-2 px-4 border-b">{item.nombre}</td>
                <td className="py-2 px-4 border-b text-center">{item.correo}</td>
                <td className="py-2 px-4 border-b text-center">{item.tipoPerfil}</td>
                <td className="py-2 px-4 border-b text-center flex justify-center space-x-2">
                <td className="py-2 px-4 border-b text-center flex justify-center space-x-2">
                  <button onClick={() => handleEdit(item.id)} aria-label="Editar">
                    <Editar className="text-blue-500 cursor-pointer hover:text-blue-700" />
                  </button>
                  <button onClick={() => handleDelete(item.id)} aria-label="Eliminar">
                  <Borrar className="text-red-500 cursor-pointer hover:text-red-700" />
                  </button>
                </td>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} className="text-center py-2">No hay datos disponibles</td>
            </tr>
          )}
        </tbody>
      </table>

      <Paginate
        pageNumber={currentPage}
        pageSize={itemsPerPage}
        totalCount={totalCount}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />

      <ToastContainer />
    </div>
  );
};

export default ListUser;

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {routes} from "../../../routesConfig";
import {ReactComponent as ArrowLeft} from "../../../assets/icons/arrow-left.svg";
import {ReactComponent as Home} from "../../../assets/icons/home.svg";

const findRouteByPath = (path: string, routes: any[], parentPath: string = ''): any | undefined => {
  for (const route of routes) {
    const fullPath = `${parentPath}/${route.path}`.replace(/\/+/g, '/');

    if (fullPath === path) {
      return route;
    } else if (route.children) {
      const childRoute = findRouteByPath(path, route.children, fullPath);
      if (childRoute) {
        return { ...childRoute, parent: route };
      }
    }
  }
  return undefined;
};

const Breadcrumbs: React.FC = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);

  let breadcrumbRoutes: any[] = [];
  pathnames.forEach((_, index) => {
    const fullPath = `/${pathnames.slice(0, index + 1).join('/')}`;
    const route = findRouteByPath(fullPath, routes);
    if (route) breadcrumbRoutes.push(route);
  });

  return (
    <div>
      {location.pathname !== '/login' && (
        <nav className={`px-6 mb-[26px] mt-[20px]`} aria-label="breadcrumb">
          <ol className="breadcrumb flex items-center">
            <Home className={`mr-2.5`}/>
            <li key={`home`} className="breadcrumb-item">
              <Link to="/dashboard">Inicio</Link>
            </li>
            {breadcrumbRoutes.map((route, index) => {
              const isLast = index === breadcrumbRoutes.length - 1;
              const fullPath = breadcrumbRoutes.slice(0, index + 1).map(r => r.path).join('/').replace(/\/+/g, '/');
              const isAdministracion = route.name === 'Administracion';
              return (
                <li key={index} className={`flex flex-row-reverse items-center breadcrumb-item ${isLast ? 'active' : ''}`}
                    aria-current={isLast ? 'page' : undefined}>
                   {isLast || isAdministracion ? (
                    route.name
                  ) : (
                    <Link to={fullPath}>{route.name}</Link>
                  )}
                  <ArrowLeft className={`mr-2.5`}/>
                </li>
              );
            })}
          </ol>
        </nav>
      )}
    </div>
  );
};


export default Breadcrumbs;
import {callApi} from "../utilservices";

export const fetchQualityReport = async (numeroPartida:number) => {

  const url = `Recepciones/Informe/${numeroPartida}`;

  try {
    return await callApi({
      url: url,
      method: 'GET',
      responseType: 'json',
    });
  } catch (error) {
    console.error('Failed to fetch informe calidad:', error);
    throw error;
  }
};
import { Link, useLocation } from "react-router-dom";
import LogoDDC from "../../../assets/Logo-DDC";
import React, { useRef, useState, useEffect } from "react";
import { ReactComponent as Home } from "../../../assets/icons/home.svg";
import { ReactComponent as Car } from "../../../assets/icons/car.svg";
import { ReactComponent as Puzzle } from "../../../assets/icons/puzzle.svg";
import { ReactComponent as Profile } from "../../../assets/icons/profile.svg";
import { ReactComponent as ProfileArrowDown } from "../../../assets/icons/profile-arrow-down.svg";
import { ReactComponent as Logout } from "../../../assets/icons/logout.svg";
import { callApi } from '../../../services/utilservices';
import { useNavigate } from 'react-router-dom';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as MenuMobile } from "../../../assets/icons/menu-mobile.svg";
import { ReactComponent as CloseMenuMobile } from "../../../assets/icons/close.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down.svg";
import { useSnackbar } from '../../../context/SnackbarContext';
import { CogIcon } from "@heroicons/react/20/solid";
import Modal from "../modal";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false); // Estado para el botón de "Bienvenido"
  const [dropdownOpen, setDropdownOpen] = useState(false); // Estado para el dropdown de perfil
  const location = useLocation();
  const isLoginPage = (location.pathname === '/login' || location.pathname === '/recovery');
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);  // ref para el dropdown
  const [rolUsuario, setRolUsuario] = useState(localStorage.getItem('perfil'));
  const { showMessage } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const nombreUsuario = localStorage.getItem('nombre') || 'Usuario';

  const [modalContent, setModalContent] = useState<React.ReactNode | string>(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
  const [dropdownMobileOpen, setDropdownMobileOpen] = useState<boolean>(false);

  const openModal = (content: React.ReactNode | string) => {

    setModalContent(content);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false); // Cierra el dropdown
        setIsOpen(false); // Cierra el "Bienvenido"
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {

  }, [isMobileMenuOpen]);

  useEffect(() => {

  }, [dropdownMobileOpen]);

  const handleNavigate = () => {
    setDropdownMobileOpen(false)
    setIsMobileMenuOpen(false)
  }

  useEffect(() => {
    const updateUserRole = () => {
      setRolUsuario(localStorage.getItem('perfil'));

    };

    updateUserRole();
    const intervalId = setInterval(updateUserRole, 1000);

    return () => clearInterval(intervalId);
  }, []);
  const handleSupportClick = () => {
    openModal(<div>
      Si tienes algún problema, comunícate a través de nuestro correo electrónico
      <span className="text-ddc-blue"> soporteTI@ddc.cl</span>
    </div>);
  };
  const handleLogout = async () => {
    setLoading(true);
    /*try {
      const response = await callApi({
        url: '/api/Login/Logout',
        method: 'POST',
      });

      if (response.satisfactorio) {
        localStorage.removeItem('token');
        localStorage.removeItem('nombre');
        localStorage.removeItem('perfil');
        localStorage.removeItem('id');
        navigate('/login');
        setLoading(false);
      } else {
        showMessage('Error al cerrar sesión', 'error');
      }
    } catch (error) {
      showMessage('Ocurrió un error al intentar cerrar sesión', 'error');
    }*/

    localStorage.clear();
    window.location.href = '/login';
    setLoading(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false); // Cierra el "Bienvenido"
      setDropdownOpen(false); // Cierra el dropdown del perfil
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleProfileClick = () => {
    setDropdownOpen(false); // Cierra el dropdown de perfil cuando se hace clic en "Mi Perfil"
  };

  const handleMenuItemClick = () => {
    setDropdownOpen(false); // Cierra el menú cuando se hace clic en cualquier otro enlace del dropdown
  };

  return (
    <div>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20 z-50">
          <div className="text-white flex items-center space-x-3">
            <CogIcon className="w-8 h-8 animate-spin" /> {/* Ícono de coche girando */}
            <span className="text-xl">Procesando...</span>
          </div>
        </div>
      )}
      <nav className="nav-desktop-menu bg-white flex flex-row align h-20">
      <a href="/dashboard">
        <LogoDDC  className="mx-6 my-4" />
        </a>
        {!isLoginPage && (
          <div className={`flex justify-between w-full items-center`}>
            <ul className="flex flex-row items-center mx-6 gap-6">
              <li key="dashboard" className="flex flex-row items-center">
                <Home className={`mr-2.5 ${(location.pathname === '/dashboard') ? 'text-[#003DA5]' : 'hidden'}`} />
                <Link to="/dashboard" className={`${(location.pathname === '/dashboard') ? 'text-[#003DA5] font-bold' : ''}`}>Inicio</Link>
              </li>
              <li key="recepciones" className="flex flex-row items-center">
                <Car className={`mr-2.5 ${(location.pathname === '/recepciones') ? 'text-[#003DA5]' : 'hidden'}`} />
                <Link to="/recepciones" className={`${(location.pathname === '/recepciones') ? 'text-[#003DA5] font-bold' : ''}`}>Recepciones</Link>
              </li>
              <li key="procesos" className="flex flex-row items-center">
                <Puzzle className={`mr-2.5 ${(location.pathname === '/procesos') ? 'text-[#003DA5]' : 'hidden'}`} />
                <Link to="/procesos" className={`${(location.pathname === '/procesos') ? 'text-[#003DA5] font-bold' : ''}`}>Procesos</Link>
              </li>
              {rolUsuario === 'Administrador' && (
                <li className="relative">
                  <button
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    className="flex items-center"
                  >
                    Administracion
                    <span className={`ml-1 ${dropdownOpen ? 'rotate-180' : ''}`}>▼</span>
                  </button>
                  {dropdownOpen && (
                    <div
                      ref={dropdownRef} // Aplicando ref para controlar el cierre del dropdown
                      className="absolute left-0 mt-2 w-48 bg-white border border-gray-300 rounded-md shadow-lg z-10"
                    >
                      <div>
                        <Link
                          to="/administracion/usuarios"
                          onClick={handleMenuItemClick}
                          className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 ${location.pathname === '/administracion/usuarios' ? 'font-bold text-[#003DA5]' : ''}`}
                        >
                          Usuarios
                        </Link>
                      </div>
                      <div>
                        <Link
                          to="/administracion/productores"
                          onClick={handleMenuItemClick}
                          className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 ${location.pathname === '/administracion/productores' ? 'font-bold text-[#003DA5]' : ''}`}
                        >
                          Productores
                        </Link>
                      </div>
                    </div>
                  )}
                </li>
              )}
            </ul>
            <div>
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="w-[202px] py-[15px] px-[12px] h-[46px] bg-[#003DA5] text-[14px] mx-6 my-4 rounded-full cursor-pointer flex justify-around font-medium items-center">
                <FontAwesomeIcon icon={faUser} className="text-white" />
                <span className="font-bold text-[#FFFFFF]">Bienvenido: {nombreUsuario}</span>
                <ProfileArrowDown className="text-white mt-1" />
              </button>
              {isOpen && (
                <div
                  ref={dropdownRef}
                  className={`absolute right-1 rounded-[10px] z-30 w-[240px] overflow-hidden bg-[#FFFFFF] shadow-[0px_8px_13px_-3px_rgba(0,0,0,0.07)]`}>
                  <div
                    // ref para el menú de perfil
                    className="cursor-pointer hover:text-white hover:bg-[#003DA5] h-[53px] flex justify-start items-center pl-[19px] text-[16px] font-normal text-black"
                  >
                    <Link to="/perfil" onClick={handleMenuItemClick} className="w-full flex justify-start items-center">
                      <FontAwesomeIcon icon={faUser} className="mr-2.5 text-black hover:text-white" />
                      <span>Mi Perfil</span>
                    </Link>
                  </div>
                  <div



                  >
                    <a
                      href="#"
                      onClick={handleSupportClick}
                      className="cursor-pointer hover:text-white hover:bg-[#003DA5] h-[53px] flex justify-start items-center pl-[19px] text-[16px] font-normal"
                    >
                      Soporte
                    </a>

                  </div>
                  <div className={`cursor-pointer hover:text-white hover:bg-[#003DA5] flex justify-center items-center h-[53px]`}>
                    <Logout />
                    <span className={`ml-[25.5px] text-[16px] font-normal`} onClick={handleLogout}>Salir de mi cuenta</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </nav>

      <Modal
        isVisible={isModalVisible}
        onClose={closeModal}
        title="Ayuda y Soporte"
        content={modalContent}
      />

      <nav className={`nav-mobile-menu bg-white flex flex-row items-center h-20 px-[19px] py-6'`} >
        <MenuMobile className={`${(isLoginPage == true) ? 'hidden' : ''}`} onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} />
        <LogoDDC className={`mx-6 my-4 ${(isLoginPage == true) ? '' : 'hidden'}`}/>
        {isMobileMenuOpen && (
          <div className="fixed top-0 left-0 w-full h-full bg-white z-40">
            <div className={`p-6`}>
              <CloseMenuMobile onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} />
            </div>
            <ul className="flex flex-col items-start p-6 gap-4">
              <li key="dashboard" className="flex flex-row items-center">
                <Home className={`mr-2.5 ${(location.pathname === '/dashboard') ? 'text-[#003DA5]' : 'hidden'}`} />
                <Link to="/dashboard" onClick={() => setIsMobileMenuOpen(false)} className={`${(location.pathname === '/dashboard') ? 'text-[#003DA5] font-bold' : ''}`}>Inicio</Link>
              </li>
              <li key="recepciones" className="flex flex-row items-center">
                <Car className={`mr-2.5 ${(location.pathname === '/recepciones') ? 'text-[#003DA5]' : 'hidden'}`} />
                <Link to="/recepciones" onClick={() => setIsMobileMenuOpen(false)} className={`${(location.pathname === '/recepciones') ? 'text-[#003DA5] font-bold' : ''}`}>Recepciones</Link>
              </li>
              <li key="procesos" className="flex flex-row items-center">
                <Puzzle className={`mr-2.5 ${(location.pathname === '/procesos') ? 'text-[#003DA5]' : 'hidden'}`} />
                <Link to="/procesos" onClick={() => setIsMobileMenuOpen(false)} className={`${(location.pathname === '/procesos') ? 'text-[#003DA5] font-bold' : ''}`}>Procesos</Link>
              </li>
              {rolUsuario === 'Administrador' && (
                <li className="relative">
                  <button onClick={() => setDropdownMobileOpen(!dropdownMobileOpen)} className="flex items-center">
                    Administración
                    <span className={`ml-1 ${dropdownMobileOpen ? 'rotate-180' : ''}`}>
                      <ArrowDown />
                    </span>
                  </button>
                  {dropdownMobileOpen && (
                    <ul className={`flex flex-col items-start p-6 gap-4`}>
                      <li key="usuarios" className="flex flex-row items-center">
                        <Link to="/administracion/usuarios" onClick={handleNavigate} className={`block text-gray-700 ${location.pathname === '/administracion/usuarios' ? 'font-bold text-[#003DA5]' : ''}`}>
                          Usuarios
                        </Link>
                      </li>
                      <li key="productores" className="flex flex-row items-center">
                        <Link to="/administracion/productores" onClick={handleNavigate} className={`block text-gray-700 ${location.pathname === '/administracion/productores' ? 'font-bold text-[#003DA5]' : ''}`}>
                          Productores
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
              )}
              <li key="logout" className="flex flex-row items-center">
                <Logout />
                <span className={`ml-[25.5px] text-[16px] font-normal`} onClick={handleLogout}>Salir de mi cuenta</span>
              </li>
            </ul>
          </div>
        )}
      </nav>
    </div>

  );
};

export default Navbar;

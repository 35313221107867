import { useState, useEffect } from "react";
import {fetchChildProducers} from "../../services/producers/fetchChildProducers";

const useChildProducers = (initialPage = 1, pageSize = 5, filterBy = "", searchText = "",parentId:number | null) => {
  const [children, setChildren] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(initialPage);

  useEffect(() => {
    const loadChildProducers = async () => {
      if (parentId === null) {
        setChildren([]);
        setLoading(false);
        return;
      }

      setLoading(true);
      setError(null);
      try {
        const data = await fetchChildProducers(page, pageSize, filterBy, searchText, parentId);
        setChildren(data);
      } catch (error:any) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    loadChildProducers();
  }, [parentId]); // Aquí aseguramos que el efecto se ejecute cuando parentId cambie

  return { children, loading, error, setPage };
};

export default useChildProducers;

import React, { useState, useEffect } from 'react';
import CustomSelect from "../../components/select";
import { callApi } from '../../../services/utilservices';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import Table from "../../components/table";
import { useSnackbar } from '../../../context/SnackbarContext';
import {ReactComponent as Borrar} from "../../../assets/icons/borrar.svg";
import { CogIcon  } from "@heroicons/react/20/solid";
import Paginate from "../../components/paginate"; // Importa tu componente Paginate
interface ProducerData {
  id: number;
  rutPadre: string;
  correo: string;
  codigoSAP: string;
  nombre: string;
  rut: string
  nombrePadre: string
}
const ListProducer: React.FC = () => {
  const [data, setData] = useState<ProducerData[]>([]);
  const { showMessage } = useSnackbar();
  const [rolUsuario, setRolUsuario] = useState(localStorage.getItem('perfil'));
  const columns = [
    'Nombre',
    'Rut',
    'Email',
    'CódigoSAP',
    'Rut Padre',  
    'Productor Padre',
  ];
  if (rolUsuario === "Administrador") {
    columns.push('Eliminar');
  }

  const customSelectOptions = [
    { label: "Filtrar Por:", value: "" },
    { label: "Nombre", value: "nombre" },
    { label: "Correo", value: "correo" },
    { label: "Código SAP", value: "codigoSAP" },
    { label: "Nombre Padre", value: "nombrePadre" },
    { label: "Rut Padre", value: "rutPadre" },
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Nuevo estado para el tamaño de página
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>("");
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await callApi({
        url: `Productor/${currentPage}/${itemsPerPage}` +
          (selectedFilter && selectedFilter !== '' ? `/${selectedFilter}` : '/null') +
          (searchText && searchText !== '' ? `/${searchText}` : '/null/0'),
        method: 'GET',
      });

      const { total, lista } = result;
      setTotalCount(total ?? 0);
      if (Array.isArray(lista)) {
        setData(lista);
      } else {
        console.error("Los datos recibidos no son un array:", lista);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage, selectedFilter, searchText]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newSize: number) => {
    setItemsPerPage(newSize);
    setCurrentPage(1); // Reiniciar a la primera página
  };
  const handleSearch = () => {
    setCurrentPage(1);
    fetchData();
  };
  const syncData = async () => {
    setLoading(true);
    try {
      const response = await callApi({
        url: 'Productor/SincronizarDatos',
        method: 'GET',
      });

      if (response) {
        showMessage('Datos Sincronizados Éxitosamente', 'success');
        setTimeout(() => {
          //window.location.reload(); 
           fetchData(); 
        }, 1000);
      } else {
        showMessage('No se pudo sincronizar los datos', 'error');
      }
    } catch (error) {
      showMessage('Erro al sincronizar los datos', 'error');
      
    } finally {
      setLoading(false);
    }
  };



  const handleDelete = (userId: number) => {
    const confirmToastId = toast.info(
      <div>
        <span>¿Estás seguro de que deseas eliminar este productor?</span>
        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}>
          <button
            onClick={() => handleDeleteConfirmed(userId, confirmToastId.toString())}
            style={{ color: 'red', marginLeft: '5px' }}
          >
            Sí, eliminar
          </button>
          <button
            onClick={() => toast.dismiss(confirmToastId)}
            style={{ marginLeft: '5px' }}
          >
            Cancelar
          </button>
        </div>
      </div>,
      {
        position: "top-center",
        autoClose: false,
      }
    );
  };

  const handleDeleteConfirmed = async (userId: number, confirmToastId: string) => {
    toast.dismiss(confirmToastId);
    setLoading(true); 
    try {
      const response = await callApi({
        url: `Productor/${userId}`,
        method: 'PUT',
      });
      fetchData();
      showMessage('Productor Eliminado exitosamente', 'success');
    } catch (error) {
      showMessage('Error eliminando el productor '+ error, 'error');
      
    }
    finally {
     
      setLoading(false); // Desactivamos el spinner una vez la petición esté completa
     
    }
  };



  const handleFilterChange = (filter: string) => {
    setSelectedFilter(filter); // Solo actualiza el filtro
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      // Ejecuta la búsqueda solo cuando se presiona "Enter"
      setCurrentPage(1); // Reiniciar la página a la 1
      fetchData();
    }
  };
  return (
    <div className="w-full p-6">
        <div className={'mb-[22px]'}>
        <h1 className={'page-title'}>Administración de Productores</h1>
        <p className={'text-gray-500 page-subtitle pt-[8px]'}>Gestiona y organiza la información de los productores.</p>
      </div>
      <hr />
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20 z-50">
          <CogIcon className="w-8 h-8 animate-spin" />
          <span className="ml-2 text-xl text-white">Procesando...</span>
        </div>
      )}

      {/* Filtros */}
      <div className="flex w-full items-center justify-center mb-[15px] mt-[34px]">
        <div className="flex w-full flex-col">
          <p className="text-xs text-gray-500 mb-2">
            Paso 1: Escoge un filtro. Paso 2: Ingresa el texto a buscar.
          </p>
          <div className="flex w-full h-[55px] p-[10px] bg-white mr-[33px] rounded-[5px] shadow-[0px_8px_13px_-3px_rgba(0,0,0,0.07)]">
            <div>
              <CustomSelect
                description=""
                childrenOptions={customSelectOptions}
                onChange={setSelectedFilter}
              />
            </div>
            <div className="h-full w-[1px] bg-gray-300 mr-[52px]"></div>
            <div className="h-full w-full">
              <input
                className="h-full w-full outline-0"
                placeholder={'Buscar aquí...'}
                type="text"
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={handleSearch}
              />
            </div>
          </div>
        </div>
      </div>


      {/* Tabla */}
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            {columns.map((column, index) => (
              <th key={index} className="py-2 px-4 border-b text-left">{column}</th>
            ))}
          </tr>
        </thead>
        <tbody className="text-sm">
          {data.length > 0 ? (
            data.map((item) => (
              <tr key={item.id}>
                <td className="py-2 px-4 border-b">{item.nombre}</td>
                <td className="py-2 px-4 border-b">{item.rut}</td>
                <td className="py-2 px-4 border-b">{item.correo}</td>
                <td className="py-2 px-4 border-b">{item.codigoSAP}</td>
                <td className="py-2 px-4 border-b">{item.rutPadre}</td>
                <td className="py-2 px-4 border-b">{item.nombrePadre}</td>
                {rolUsuario === "Administrador" && (
                <td className="py-2 px-4 border-b text-center flex justify-center space-x-2">
                  <button onClick={() => handleDelete(item.id)} aria-label="Eliminar">
                    <Borrar className="text-red-500 cursor-pointer hover:text-red-700" />
                  </button>
                  
                </td>
                 )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} className="text-center py-4">
                No hay datos disponibles
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Componente Paginate */}
      <Paginate
        pageNumber={currentPage}
        pageSize={itemsPerPage}
        totalCount={totalCount}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />

      <ToastContainer />
    </div>
  );
};

export default ListProducer;

import {useState} from "react";
import {handleDownloadBase64} from "../../services/utilservices";
import {ExportExcelReceptionsInterface} from "../../services/receptions/interfaces/exportExcelReceptionsInterface";
import {fetchExportReceptions} from "../../services/receptions/fetchExportReceptions";

const useExportExcelReceptions = () => {
  const [loadingExport, setLoading] = useState(false);
  const [errorExport, setError] = useState(null);

  const exportToExcel = async (filters:ExportExcelReceptionsInterface[] = []) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchExportReceptions(filters);
      
      const now = new Date();

      // Obtener los componentes de la fecha
      const year = now.getFullYear().toString().slice(-2); // Obtiene los últimos dos dígitos del año
      const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Mes (agregar 1 porque los meses empiezan desde 0)
      const day = now.getDate().toString().padStart(2, '0'); // Día con ceros a la izquierda

      // Formatear a AAMMDD
      const formattedDate = `${year}${month}${day}`;

      let fieldName = localStorage.getItem('species') || '';
      //fieldName = fieldName + "_" + localStorage.getItem('parentProducer')?.split("-")[0] || fieldName + '';
      fieldName = fieldName + "_" + (localStorage.getItem('parentProducer')?.split("-")[0] || '');
      if (response) {
        handleDownloadBase64(response, `Recepciones_${fieldName}_${formattedDate}.xlsx`, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
      } else {
        console.error("Error en la operación:", response?.data.mensaje);
      }
    } catch (error:any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { exportToExcel, loadingExport, errorExport };
}

export default useExportExcelReceptions;
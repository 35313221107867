import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const InactivityTimeout: React.FC = () => {
  const [remainingTime, setRemainingTime] = useState<number>(15 * 60 * 1000); // 1 minuto en milisegundos
  const navigate = useNavigate();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null); // Usamos useRef para almacenar el timeout

  // Función para resetear el temporizador de inactividad
  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      //alert("Tu sesión ha expirado por inactividad.");
      localStorage.removeItem('token');
      localStorage.removeItem('nombre');
      localStorage.removeItem('perfil');
      localStorage.removeItem('parentProducer');
      localStorage.removeItem('parentProducerSearch');
      
      navigate("/login");
    }, remainingTime);
  };

  // Monitoreo de actividad del usuario
  const handleActivity = () => {
    resetTimeout(); // Reinicia el temporizador cada vez que el usuario interactúa
  };

  useEffect(() => {
    // Agregar eventos de actividad
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('click', handleActivity);
    window.addEventListener('scroll', handleActivity);
    window.addEventListener('touchstart', handleActivity);

    // Detectar cambio de visibilidad (si el usuario cambia de pestaña)
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        resetTimeout(); // Reinicia el temporizador cuando la pestaña vuelva a ser visible
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Inicia el temporizador
    resetTimeout();

    return () => {
      // Limpiar los eventos y el temporizador al desmontar el componente
      clearTimeout(timeoutRef.current!);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('click', handleActivity);
      window.removeEventListener('scroll', handleActivity);
      window.removeEventListener('touchstart', handleActivity);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [remainingTime]); // Solo dependemos de `remainingTime` para el inicio del temporizador, no lo usamos para resetear el timeout

  return null; // Este componente no renderiza nada, solo maneja la lógica de inactividad
};

export default InactivityTimeout;

import React, { useEffect, useMemo, useState } from 'react';
import Table from "../components/table";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import useProcesses from "../../hooks/processes/useProcesses";
import { formatNumber } from '../../services/utilservices';
import { Column } from "../components/table/interfaces/tableInterfaces";
import { ProcessDataInterface } from "./intefaces/processDataInterface";
import { FilterByInterface } from "../components/FilterBy/interfaces/filterByInterface";
import FilterBy from "../components/FilterBy";
import useExportExcelProcesses from "../../hooks/processes/useExportExcelProcesses";
import { filterDataInterface } from "../components/FilterBy/interfaces/filterByPropsInterface";
import Filtros from "../dashboard/widgets/filtros";
import { useProcessReport } from "../../hooks/processes/useProcessReport";
import { PaginationInterface } from "../components/paginate/interfaces/paginationInterface";
import { CogIcon } from "@heroicons/react/20/solid";
import { filter } from 'lodash';

// Función para formatear la fecha
const formatDate = (dateString: string) => {
  // Asegurarse de cortar los milisegundos adicionales si es necesario
  const validDateString = dateString.slice(0, 19); // Elimina milisegundos adicionales y fracción
  const date = new Date(validDateString); // Crear objeto Date

  // Verificar si la fecha es válida
  if (isNaN(date.getTime())) {
    return "Fecha inválida"; // Devolver un mensaje si la fecha es inválida
  }

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses comienzan desde 0
  const year = date.getFullYear();
  //console.log(`${day}/${month}/${year}`)
  return `${day}-${month}-${year}`;
};


interface Filter {
  filtrarPor: string;
  textoBusqueda: string;
  isLike: boolean;
}

const Process: React.FC = ({ productorPadreId, productorId, temporadaId, especieId, variedadId }: any) => {
  const { getProcessReport } = useProcessReport();
  const [loading, setLoading] = useState(false);
  const perfil = localStorage.getItem("perfil");
  const species = localStorage.getItem("species");

  useEffect(() => {
    console.log('Estado de carga actualizado:', loading);
  }, [loading]);
  const handleProcessReport = (data: string[]): { data: null; loading: boolean; error: null | any } => {
   
    setLoading(true);  // Activamos el estado de carga
   
    const lote: string = data[0];
    const especie: string = data[1];
    const variedad: string = data[2];
    const codigo = data[3].split("-");
    const result = { data: null, loading: true, error: null };

    getProcessReport(lote, especie,variedad,codigo[0])
      .then((response) => {
        setLoading(false);  // Desactivamos el estado de carga

        //console.log(response);
        return { data: null, loading: false, error: null };
      })
      .catch((error) => {
        setLoading(false);  // Desactivamos el estado de carga en caso de error
        console.error('Error fetching process report:', error);

        // Retornamos el estado con error
        return { data: null, loading: false, error };
      });

    return result;  // Retorna inmediatamente el estado inicial
  };



  const columns: Column[] = [
    { column_name: 'productorNombre', name: 'Productor', type: "string", styleClassCell: '' },
    { column_name: 'variedadNombre', name: 'Variedad', type: "string", styleClassCell: 'text-center' },
    { column_name: 'lote', name: 'Lote Proceso', type: "string", styleClassCell: 'text-center' },
    { column_name: 'fechaProceso', name: 'Fecha Proceso', type: "string", styleClassCell: 'text-center' },
    { column_name: 'centroNombre', name: 'Nombre Centro', type: "string", styleClassCell: '' },
    { column_name: 'kilosProcesados', name: 'Procesados (Kg)', type: "number", styleClassCell: 'text-right' },
    { column_name: 'porcentajeExportacion', name: 'Exportación (%)', type: "string", styleClassCell: 'text-right' },
    { column_name: ['lote', 'especieNombre', 'variedadNombre','productorNombre'], name: 'Informe Proceso', type: "api", api: handleProcessReport, styleClassCell: 'text-center' },
  ];

  const [pagination, setPagination] = useState<PaginationInterface | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [lista, setData] = useState<ProcessDataInterface[]>([]);
  const [selectedParentProducerId, setSelectedParentProducerId] = useState(productorPadreId);
  const [selectedProducerId, setSelectedProducerId] = useState(productorId);
  const [selectedSeasonId, setSelectedSeasonId] = useState(temporadaId);
  const [selectedSpeciesId, setSelectedSpeciesId] = useState(especieId);
  const [selectedVarietyId, setSelectedVarietyId] = useState(variedadId);
  const [isFiltersSynced, setIsFiltersSynced] = useState(false);

  const [customRow, setCustomRow] = useState([
    { content: 'SUBTOTAL', colspan: 5, }, { content: '0 Kg', colspan: 1, },
  ]);

  const customSelectFilterByOptions = [
    { label: "Filtrar Por:", value: "" },
    { label: "Lote", value: "lote" },
    { label: "Centro Nombre", value: "centroNombre" },
    { label: "Fecha Proceso", value: "fechaProceso" },
  ];

  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem('filters') || '{}');
    if (Object.keys(savedFilters).length) {
      setSelectedParentProducerId(savedFilters.productorPadreId || productorPadreId);
      setSelectedProducerId(savedFilters.productorId || productorId);
      setSelectedSeasonId(savedFilters.temporadaId || temporadaId);
      setSelectedSpeciesId(savedFilters.especieId || especieId);
      setSelectedVarietyId(savedFilters.variedadId || variedadId);
    }
    setIsFiltersSynced(true);
  }, []);

  const filters = useMemo(() => ({
    productorId: selectedProducerId,
    productorPadreId: selectedParentProducerId,
    temporadaId: selectedSeasonId,
    especieId: selectedSpeciesId,
    variedadId: selectedVarietyId,
  }), [selectedParentProducerId, selectedProducerId, selectedSeasonId, selectedSpeciesId, selectedVarietyId]);

  const [filterBy, setFilterBy] = useState<FilterByInterface>({
    pageNumber: 1,
    pageSize: 10,
    paginationFilters: [],
  });

  const { processes, loadingProccess } = useProcesses(filterBy, isFiltersSynced);
  const { exportToExcel, loadingExport } = useExportExcelProcesses();



  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    //console.log(newPage,'newPage')
    setFilterBy(prevFilterBy => ({
      ...prevFilterBy,
      pageNumber: newPage,  // Asegúrate de actualizar correctamente el número de página
    }));
  };

  const handlePageSizeChange = (newSize: number) => {
    setCurrentPageSize(newSize);
    // console.log(newSize,'newSize')
    setFilterBy(prevFilterBy => ({
      ...prevFilterBy,
      pageSize: newSize,
    }));
  };

  const handleExport = () => {
    exportToExcel(filterBy.paginationFilters);

  };

  useEffect(() => {

    if (processes?.lista?.length) {
      if (processes.pagPrevia === null) {
        processes.pagPrevia = 1;
      }
      const paginationData: PaginationInterface = {
        pageSize: 0,
        pageNumber: 0,
        totalCount: processes.total
      }

      //const items:any = processes.data.items
      //console.log(processes.lista)
      const lista: any = processes.lista.map((lista: any) => {
        return {
          ...lista,
          fechaProceso: formatDate(lista.fechaProceso), // Formatear la fecha aquí
          porcentajeExportacion: (lista.porcentajeExportacion.toFixed(1) ) + "%"
        };
      });

      /*const subtotal = items.reduce((sum: number, item: any) => {
        return sum + (parseFloat(item.kilosProcesados) || 0);
      }, 0);*/
      const subtotal = processes.lista[0].totalProcesados;
      setData(lista);
      setPagination(paginationData);

      setCustomRow(prevCustomRow => [
        prevCustomRow[0],
        {
          ...prevCustomRow[1],
          content: `${formatNumber(subtotal)} Kg`,
        },

      ]);

    }
    else {
      setPagination({
        pageSize: 0,
        pageNumber: 0,
        totalCount: 0,
      });

      setCustomRow(prevCustomRow => [
        prevCustomRow[0],
        {
          ...prevCustomRow[1],
          content: `${formatNumber(0)} Kg`,
        },

      ]);

      // También puedes limpiar la data si es necesario
      setData([]);
      //setCustomRow([]);
    }
  }, [processes?.lista]);
 
  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem('filters') || '{}');

    if (filters.especieId == savedFilters.speciesId && filters.productorId == savedFilters.producerId && filters.productorPadreId == savedFilters.parentProducerId && filters.temporadaId == savedFilters.seasonId && filters.variedadId == savedFilters.varietyId) {
      const newPaginationFilters = Object.keys(filters).reduce((acc, key) => {
        const filterValue = filters[key as keyof typeof filters];
        if (filterValue) {
          acc.push({
            filtrarPor: key,
            textoBusqueda: `${filterValue}`,
            isLike: false,
          });
        }
        return acc;
      }, [] as any[]);

      setCurrentPage(1);
      setFilterBy(prevFilterBy => ({
        ...prevFilterBy,
        paginationFilters: newPaginationFilters,
        pageNumber: 1,
      }));

      setIsFiltersSynced(true);
    } else {
      setIsFiltersSynced(false);
    }
  }, [filters, isFiltersSynced]);


  const handleFilterChange = (filterData: filterDataInterface) => {
    setFilterBy(prevFilterBy => {
      const existingFilters: Filter[] = prevFilterBy.paginationFilters || [];
      let updatedFilters: Filter[];

      // Si no hay búsqueda ni filtro seleccionado, eliminamos el último filtro
      if (filterData.selectedFilter === '' && filterData.searchText === '') {
        updatedFilters = existingFilters.slice(0, existingFilters.length - 1); // Elimina el último filtro
      } else {
        // Si el campo de búsqueda está vacío pero se ha seleccionado un filtro, desactivamos la búsqueda "like"
        if (filterData.searchText === '') {
          updatedFilters = existingFilters.map((filter: Filter) => {
            if (filter.filtrarPor === filterData.selectedFilter) {
              return {
                ...filter,
                textoBusqueda: '',  // Limpiamos el textoBusqueda
                isLike: false,  // Desactivamos la búsqueda "like"
              };
            }
            return filter;
          });

          // Si no existe un filtro con el filtro seleccionado, lo agregamos
          const filterExists = updatedFilters.some((filter: Filter) => filter.filtrarPor === filterData.selectedFilter);

          if (!filterExists) {
            updatedFilters.push({
              filtrarPor: filterData.selectedFilter,
              textoBusqueda: '',  // Limpiamos el textoBusqueda
              isLike: false,  // Desactivamos la búsqueda "like"
            });
          }
        } else {
          // Si el campo de búsqueda tiene texto, lo aplicamos al filtro
          updatedFilters = existingFilters.map((filter: Filter) => {
            if (filter.filtrarPor === filterData.selectedFilter) {
              return {
                ...filter,
                textoBusqueda: filterData.searchText,
                isLike: true,  // Activamos la búsqueda "like"
              };
            }
            return filter;
          });

          // Si no existe un filtro con el filtro seleccionado, lo agregamos con el valor de búsqueda
          const filterExists = updatedFilters.some((filter: Filter) => filter.filtrarPor === filterData.selectedFilter);

          if (!filterExists) {
            updatedFilters.push({
              filtrarPor: filterData.selectedFilter,
              textoBusqueda: filterData.searchText,
              isLike: true,
            });
          }
        }
      }

      // Devolvemos el estado actualizado con los filtros modificados
      return {
        ...prevFilterBy,
        paginationFilters: updatedFilters,
      };
    });

    // Si no hay texto de búsqueda y no se seleccionó filtro, solo reseteamos la página
    setCurrentPage(1); // Resetear la página siempre, independientemente del estado de los filtros
  };


  let prod = localStorage.getItem('prod');
  let parsedProd = prod ? JSON.parse(prod) : [];
  return (
    <div className="w-full">
       {(loadingProccess || loading) && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20 z-50">
          <div className="text-white flex items-center space-x-3">
            <CogIcon className="w-8 h-8 animate-spin" /> {/* Ícono de coche girando */}
            <span className="text-xl">Procesando...</span>
          </div>
        </div>
      )}

      <div className={`py-[23px] px-[35px] min-h-[181px] w-full bg-[linear-gradient(90deg,_#01AAEF_0.72%,_#2C2D65_100%)]`}>
        <div className={'mb-[22px]'}>
          <h1 className={'font-bold text-[24px] page-title text-[#FFFFFF]'}>Procesos</h1>
          <p className={'text-[12px] pt-[8px] page-subtitle text-[#FFFFFF] flex'}>Resultados de Procesos y Reportes de Producción.					<InformationCircleIcon className="size-5 text-[#FFFFFF]" /></p>
        </div>
        <div className={'flex flex-wrap items-center mb-[20px] min-h-[40px]'}>
          <Filtros
            column={`nombrePadre`}
            onProducerChange={setSelectedProducerId}
            onParentProducerChange={setSelectedParentProducerId}
            onSeasonChange={setSelectedSeasonId}
            onSpeciesChange={setSelectedSpeciesId}
            onVarietyChange={setSelectedVarietyId}
          />
        </div>
      </div>
      <hr />
      <div className={`p-6`}>
        <p className="text-xs text-gray-500 mb-2">
          Paso 1: Escoge un filtro. Paso 2: Ingresa el texto a buscar.
        </p>
        <div className="flex w-full items-center justify-center mb-[15px] mt-[12px]">
          <FilterBy childrenOptions={customSelectFilterByOptions} onFilterChange={handleFilterChange} />
          {(parsedProd.length > 0 || perfil !== "Productor") && ( 
          <button
            onClick={handleExport}
            className={`flex justify-center items-center w-[300px] h-[42px] rounded-[6px] text-white ml-3 ${perfil === "Productor" && species === "ARANDANOS"
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-[#00C7B1]"
              }`}
            disabled={loadingExport || (perfil === "Productor" && species === "ARANDANOS")}
          >
            {loadingExport ? (
              <>
                <span className="loader mr-2"></span>
                <span>Cargando...</span>
              </>
            ) : perfil === "Productor" && species === "ARANDANOS" ? (
              <span className="text-gray-200">Datos no disponibles</span>
            ) : (
              <>
                <span className="flex items-center">
                  Consolidado de proceso
                  <DownloadIcon className="ml-2" />
                </span>
              </>
            )}
          </button>
          )}

        </div>

        <Table columns={columns} data={lista} customRow={customRow} pagination={pagination} onPageChange={handlePageChange} onPageSizeChange={handlePageSizeChange} />


      </div>
    </div>
  );
}

export default Process;
interface IDDCButton 
{
  click_action: () => void;
  b_text: string | JSX.Element; // Permitir string o JSX.Element
  disabled?: boolean;
}

export default function DDCButton({ click_action, b_text, disabled }: IDDCButton) {
  return (
    <div className="w-full">
      <button 
        className={`w-full border border-ddc-blue rounded-md h-11 text-ddc-blue font-medium hover:bg-ddc-blue hover:text-white ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        onClick={click_action}
        disabled={disabled} // Aplicar el estado deshabilitado aquí
      >
        {b_text}
      </button>
    </div>
  );
}

import { callApi } from "../utilservices";
import { toast, ToastContainer } from 'react-toastify';
const findIdRegisterByLote = (data: any[], lote: number) => {
    // Ajusta la lógica según la estructura de los datos en 'data'
    const item = data.find(entry => entry.Lote == lote); 
    return item ? item.id_register : null;
};

export const fetchPTIReport = async (numeroPartida: number) => {
   // const url1 = `Recepciones/PtiConsultalote`;
 const url1= `Recepciones/DescargarReportePTI`;
    try {
        // Realiza la primera petición
       // const url2 = `Recepciones/PtiInformeLote`;
        return await callApi({
            url: url1,
            method: 'POST',
            data: {
                
                numeroPartida: String(numeroPartida),
            },
            responseType: 'json',
        });

    } catch (error) {
        toast.warning("Informe no encontrado", { position: "top-center" });
        console.error('Error al obtener el informe de calidad:', error);
        throw error;
    }
};

import React from "react";
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";

interface DataPoint {
  name: string;
  recepcionReal: number;
  recepcionEstimada: number;
}

interface BarChartProps {
  data: DataPoint[];
  productorNombre: '';
}

const BarChart: React.FC<BarChartProps> = ({ data, productorNombre }) => {
  const currentDate = new Date().toLocaleDateString('es-ES');

  // Función para formatear los valores grandes y escalarlos
  const formatYAxis = (value: number) => {
    if (value >= 1e12) {
      return `${(value / 1e12).toFixed(1)}T`; // Miles de millones (billones en español)
    } else if (value >= 1e9) {
      return `${(value / 1e9).toFixed(1)}B`; // Miles de millones
    } else if (value >= 1e6) {
      return `${(value / 1e6).toFixed(1)}M`; // Millones
    } else if (value >= 1e3) {
      return `${(value / 1e3).toFixed(1)}K`; // Miles
    } else {
      return value.toString(); // Menores a 1000, se muestran tal cual
    }
  };

  return (
    <div className="shadow-sm border rounded-lg mt-4 px-8 py-8 w-full h-full border-b default-font">
      <div className="flex flex-row justify-between">
        <div>
          <p className="text-2xl font-bold">Recepciones Semanales (Kg)</p>
          <p className="text-md mb-6">Última actualización {currentDate} </p>
        </div>
        <div className="flex flex-col gap-3 mr-10">
          <div className="flex items-center">
            <div className="border rounded-[10px] bg-percent1-segregacion h-[10px] w-[10px] mr-1"></div>
            <p className="mr-6">Real recepcionado (Kg)</p>
          </div>
           {/*<div className="flex items-center">
            <div className="border rounded-[10px] bg-percent2-recepcion h-[10px] w-[10px] mr-1"></div>
           <p>Estimación</p>
          </div>*/}
        </div>
      </div>
      <div className="px-4 lg:flex lg:justify-center">
        <ResponsiveContainer width="100%" height={500}>
          <ComposedChart width={1300} height={500} data={data}>
            <XAxis dataKey="semana" padding={"gap"}>
              <Label value="semana" offset={-2} position="insideBottom" />
            </XAxis>
            <YAxis
              tickCount={8}
              domain={[0, 'auto']}
              tickFormatter={formatYAxis} // Usamos la función de formato aquí
            >
              <Label
                value="KG"
                position="left"
                offset={0}
                angle={-90}
                style={{ fontSize: '9px' }} // Cambia el tamaño de la etiqueta 'KG'
              />
            </YAxis>
            {/*<Tooltip
              formatter={(value: number, name: string) => {
                const formattedValue = new Intl.NumberFormat("es-ES").format(value);
                const labelName = name === 'kilosRecep' ? 'Kilos recepcionado (KG)' : 'Estimación (KG)';
                return [formattedValue, labelName];
              }}
              labelFormatter={(label) => `Semana: ${label}`}
              contentStyle={{ fontSize: "14px" }}
            />*/}
            <Tooltip
                formatter={(value: number, name: string) => {
                  const formattedValue = new Intl.NumberFormat("es-ES").format(value);
                  const labelName = name === 'kilosRecep' ? 'Recepcionado (Kg)' : '';
                  return [formattedValue, labelName];
                }}
                labelFormatter={(label) => `Semana: ${label}`}
                contentStyle={{ fontSize: "14px" }}
              />


            <CartesianGrid vertical={false} horizontal={true} stroke="#f5f5f5" />
            <Bar
              dataKey="kilosRecep"
              barSize={50}
              fill="rgba(0, 61, 165, 1)"
            />
         {/* <Line
              type="linear"
              dataKey="estimacion"
              stroke="rgba(255, 157, 110, 1)"
              fill="rgba(255, 157, 110, 1)"
            />*/}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default BarChart;

import React, { useState, useRef, useEffect } from "react";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";

interface Option {
  name: string;
  kilograms: number;
  percent: number;
}

interface ICustomDropdown {
  description: string;
  childrenOptions: Option[];
  icon?: string;
  SVGIcon?: React.ComponentType;
}

const DropdownRowInfo: React.FC<ICustomDropdown> = ({
  description, childrenOptions, icon, SVGIcon
}) => {

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null); // Crear una referencia para el dropdown

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false); // Cierra el dropdown si se hace clic fuera de él
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside); // Agrega el evento de clic al documento

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Limpia el evento al desmontar el componente
    };
  }, []);

  const colors = ['#00c7b1', '#41b6e6', '#3b00ed', '#9c27b0', '#d81b60', '#ff9800', '#3dcd7e', '#ffd900']

  const randomColor = () => {
    return colors[Math.floor(Math.random() * 7) + 0]
  }

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full bg-[#C2EDFF] rounded-custom border-gray-300 shadow-sm font-bold text-left cursor-pointer flex justify-between items-center py-[2px]"
      >
        {/*<img src={icon} alt="" width={45} height={45}/>*/}
        <div className={`icon-chart-blue h-[45px] w-[45px]`}>
          {SVGIcon? <SVGIcon/> : <img src={icon} alt="" width={45} height={45} />}
        </div>
        <span className="font-semibold text-[14px] pr-2 ml-2 mr-5">{description}</span>
        {/*
        <ChevronDownIcon
          className="group pointer-events-none absolute top-4 right-2 size-4 text-font-blue-gray"
          aria-hidden="true"
        />
        */}
      </button>

      {/*isOpen*/ false && (
        <div className="absolute mt-2 w-[350px] bg-white rounded-md shadow-lg z-10 py-2">
          <div className="px-4 pt-1 text-center font-bold text-xl">
            <h5>{description}</h5>
          </div>
          <div className="px-6 py-3">
            {childrenOptions.map((item, index) => (
              <div key={index} className="flex flex-col">
                <div className="flex flex-row justify-between text-gray-500">
                  <div>
                    <p className="">{item.name}</p>
                  </div>
                  <div className="flex gap-3 mb-1">
                    <p className="">{item.kilograms}</p>
                    <p className="">{item.percent}%</p>
                  </div>
                </div>

                <div className="flex flex-row justify-between items-center gap-3 mb-3">
                  <hr
                    style={{ width: `${item.percent}%`, height: "5px", backgroundColor: randomColor() }}
                    className="rounded-sm"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownRowInfo;
